// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Scalars = require("../../../scalars/Scalars.bs.js");
var Core__Array = require("@rescript/core/src/Core__Array.bs.js");
var CurrentUser = require("../../../reason/common/CurrentUser/CurrentUser.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var ReadMoreButton = require("../../../components/buttons/ReadMoreButton.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var NotificationsUtils = require("../../../v5/common/notifications/NotificationsUtils.bs.js");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var FragmentErrorBoundary = require("../../../v5/common/ErrorHandling/FragmentErrorBoundary.bs.js");
var NotificationWidgetItem = require("./NotificationWidgetItem.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var NotificationWidgetRelayQuery_graphql = require("../../../__generated__/NotificationWidgetRelayQuery_graphql.bs.js");
var NotificationWidgetRelay_MarkNotificationReadByCurrentUserMutation_graphql = require("../../../__generated__/NotificationWidgetRelay_MarkNotificationReadByCurrentUserMutation_graphql.bs.js");

var convertVariables = NotificationWidgetRelayQuery_graphql.Internal.convertVariables;

var convertResponse = NotificationWidgetRelayQuery_graphql.Internal.convertResponse;

var convertWrapRawResponse = NotificationWidgetRelayQuery_graphql.Internal.convertWrapRawResponse;

function use(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.useQuery(convertVariables, NotificationWidgetRelayQuery_graphql.node, convertResponse, param, param$1, param$2, param$3, param$4);
}

function useLoader(param) {
  return RescriptRelay_Query.useLoader(convertVariables, NotificationWidgetRelayQuery_graphql.node, (function (prim) {
                return prim;
              }), param);
}

function usePreloaded(param) {
  return RescriptRelay_Query.usePreloaded(NotificationWidgetRelayQuery_graphql.node, convertResponse, (function (prim) {
                return prim;
              }), param);
}

function $$fetch(param, param$1, param$2, param$3, param$4, param$5) {
  return RescriptRelay_Query.$$fetch(NotificationWidgetRelayQuery_graphql.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4, param$5);
}

function fetchPromised(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.fetchPromised(NotificationWidgetRelayQuery_graphql.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4);
}

function retain(param, param$1) {
  return RescriptRelay_Query.retain(NotificationWidgetRelayQuery_graphql.node, convertVariables, param, param$1);
}

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

var convertVariables$1 = NotificationWidgetRelay_MarkNotificationReadByCurrentUserMutation_graphql.Internal.convertVariables;

var convertResponse$1 = NotificationWidgetRelay_MarkNotificationReadByCurrentUserMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$1 = NotificationWidgetRelay_MarkNotificationReadByCurrentUserMutation_graphql.Internal.convertWrapRawResponse;

function commitMutation(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
  return RescriptRelay_Mutation.commitMutation(convertVariables$1, NotificationWidgetRelay_MarkNotificationReadByCurrentUserMutation_graphql.node, convertResponse$1, convertWrapRawResponse$1, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8);
}

function use$1(param) {
  return RescriptRelay_Mutation.useMutation(convertVariables$1, NotificationWidgetRelay_MarkNotificationReadByCurrentUserMutation_graphql.node, convertResponse$1, convertWrapRawResponse$1, param);
}

var MarkNotificationReadByCurrentUserMutation_notificationStatus_decode = NotificationWidgetRelay_MarkNotificationReadByCurrentUserMutation_graphql.Utils.notificationStatus_decode;

var MarkNotificationReadByCurrentUserMutation_notificationStatus_fromString = NotificationWidgetRelay_MarkNotificationReadByCurrentUserMutation_graphql.Utils.notificationStatus_fromString;

var MarkNotificationReadByCurrentUserMutation = {
  notificationStatus_decode: MarkNotificationReadByCurrentUserMutation_notificationStatus_decode,
  notificationStatus_fromString: MarkNotificationReadByCurrentUserMutation_notificationStatus_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables$1,
  convertResponse: convertResponse$1,
  convertWrapRawResponse: convertWrapRawResponse$1,
  commitMutation: commitMutation,
  use: use$1
};

function NotificationWidgetRelay(props) {
  var data = use({
        limit: props.limit
      }, undefined, undefined, undefined, undefined);
  var isImpersonated = CurrentUser.useIsImpersonated();
  var match = use$1();
  var markNotificationAsread = match[0];
  var markAsRead = function (id) {
    if (isImpersonated) {
      return ;
    } else {
      Curry._8(markNotificationAsread, {
            input: {
              id: id
            }
          }, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
      return ;
    }
  };
  var notifications = Core__Array.filterMap(Core__Option.getOr(Core__Option.flatMap(data.notificationsForCurrentUserV5, (function (v) {
                  return v.edges;
                })), []), (function (v) {
          if (v !== undefined) {
            return v.node;
          }
          
        }));
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: NotificationsUtils.NotificationGrouper.groupNotifications(notifications, (function (v) {
                                return Curry._1(Scalars.DateTime.toUTC, v.createdAt);
                              })).map(function (x) {
                            var groupTime = NotificationsUtils.NotificationGrouper.toString(x[0]);
                            return JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsx("h3", {
                                                children: groupTime,
                                                className: "text-3xl font-medium"
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: x[1].map(function (v) {
                                                      return JsxRuntime.jsx(FragmentErrorBoundary.make, {
                                                                  fragmentDisplayName: "notification widget item",
                                                                  children: JsxRuntime.jsx(NotificationWidgetItem.make, {
                                                                        notification: v.fragmentRefs,
                                                                        markAsRead: markAsRead
                                                                      })
                                                                }, v.nodeId);
                                                    }),
                                                className: "mt-4"
                                              })
                                        ]
                                      }, groupTime);
                          }),
                      className: "mb-8 flex flex-col space-y-12"
                    }),
                props.showMore ? JsxRuntime.jsx(ReadMoreButton.make, {
                        href: "/notifications",
                        children: "View All"
                      }) : null
              ]
            });
}

var NotificationGrouper;

var make = NotificationWidgetRelay;

exports.NotificationGrouper = NotificationGrouper;
exports.Query = Query;
exports.MarkNotificationReadByCurrentUserMutation = MarkNotificationReadByCurrentUserMutation;
exports.make = make;
/* Scalars Not a pure module */
