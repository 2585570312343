// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Js_exn = require("rescript/lib/js/js_exn.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Js_array = require("rescript/lib/js/js_array.js");
var Core__Array = require("@rescript/core/src/Core__Array.bs.js");
var GraphQLConverter = require("../../common/GraphQLConverter.bs.js");

function make(id, firstName, lastName, email) {
  return {
          id: id,
          firstName: firstName,
          lastName: lastName,
          email: email
        };
}

function displayName(v) {
  var match = v.firstName;
  var match$1 = v.lastName;
  if (match !== undefined) {
    if (match$1 !== undefined) {
      return match + (" " + match$1);
    } else {
      return match;
    }
  } else if (match$1 !== undefined) {
    return match$1;
  } else {
    return ;
  }
}

var ppx_printed_query = "fragment contact on Contact   {\nid  \nfirstName  \nlastName  \nemail  \n}\n";

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  if (value$1 === undefined) {
    return Js_exn.raiseError("Unexpected GraphQL query response");
  }
  var value$2 = Js_dict.get(value$1, "id");
  var field_id = value$2 !== undefined ? value$2 : Js_exn.raiseError("Unexpected GraphQL query response");
  var value$3 = Js_dict.get(value$1, "firstName");
  var field_firstName = value$3 !== undefined && !(value$3 == null) ? value$3 : undefined;
  var value$4 = Js_dict.get(value$1, "lastName");
  var field_lastName = value$4 !== undefined && !(value$4 == null) ? value$4 : undefined;
  var value$5 = Js_dict.get(value$1, "email");
  var field_email = value$5 !== undefined && !(value$5 == null) ? value$5 : undefined;
  return {
          id: field_id,
          firstName: field_firstName,
          lastName: field_lastName,
          email: field_email
        };
}

var name = "contact";

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var Contact = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: name,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var Fragment = {
  Contact: Contact
};

function decode(obj) {
  return make(obj.id, obj.firstName, obj.lastName, obj.email);
}

var Contact$1 = {
  make: make,
  displayName: displayName,
  Fragment: Fragment,
  decode: decode
};

function make$1(id, contacts) {
  return {
          id: id,
          contacts: contacts
        };
}

function decode$1(client) {
  var partial_arg = GraphQLConverter.Helpers.ok(GraphQLConverter.Helpers.decodeRequired("contacts array was None", (function (param) {
              return GraphQLConverter.Helpers.decodeArray((function (param) {
                            return GraphQLConverter.Helpers.decodeRequired("contact was None", (function (v) {
                                          return {
                                                  TAG: "Ok",
                                                  _0: decode(v)
                                                };
                                        }), param);
                          }), param);
            }), client.contacts));
  var partial_arg$1 = GraphQLConverter.Helpers.$$const(client.id);
  var partial_arg$2 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.source(client, param);
                }), partial_arg$1, param);
  };
  return GraphQLConverter.Helpers.run(function (id, contacts) {
                return {
                        id: id,
                        contacts: contacts
                      };
              })(function (param) {
              return GraphQLConverter.Helpers.flatMap(partial_arg$2, partial_arg, param);
            });
}

function label(v) {
  var x = Core__Array.filterMap(v.contacts, displayName);
  if (x.length !== 0) {
    return x.join(", ");
  }
  
}

var ppx_printed_query$1 = "fragment client on Client   {\nid  \ncontacts  {\n...contact   \n}\n\n}\nfragment contact on Contact   {\nid  \nfirstName  \nlastName  \nemail  \n}\n";

function parse$1(value) {
  var value$1 = Js_json.decodeObject(value);
  if (value$1 === undefined) {
    return Js_exn.raiseError("Unexpected GraphQL query response");
  }
  var value$2 = Js_dict.get(value$1, "id");
  var field_id = value$2 !== undefined ? value$2 : Js_exn.raiseError("Unexpected GraphQL query response");
  var value$3 = Js_dict.get(value$1, "contacts");
  var field_contacts = value$3 !== undefined && !(value$3 == null) ? Js_array.map((function (value) {
            if (!(value == null)) {
              return parse(value);
            }
            
          }), value$3) : undefined;
  return {
          id: field_id,
          contacts: field_contacts
        };
}

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var Client = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  name: "client",
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

var ppx_printed_query$2 = "fragment decodedClient on Client   {\nid  \ncontacts  {\n...contact   \n}\n\n}\nfragment contact on Contact   {\nid  \nfirstName  \nlastName  \nemail  \n}\n";

function parse$2(value) {
  var value$1 = Js_json.decodeObject(value);
  var tmp;
  if (value$1 !== undefined) {
    var value$2 = Js_dict.get(value$1, "id");
    var field_id = value$2 !== undefined ? value$2 : Js_exn.raiseError("Unexpected GraphQL query response");
    var value$3 = Js_dict.get(value$1, "contacts");
    var field_contacts = value$3 !== undefined && !(value$3 == null) ? Js_array.map((function (value) {
              if (!(value == null)) {
                return parse(value);
              }
              
            }), value$3) : undefined;
    tmp = {
      id: field_id,
      contacts: field_contacts
    };
  } else {
    tmp = Js_exn.raiseError("Unexpected GraphQL query response");
  }
  return decode$1(tmp);
}

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

var DecodedClient = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  name: "decodedClient",
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2
};

var Fragment$1 = {
  Client: Client,
  DecodedClient: DecodedClient
};

exports.Contact = Contact$1;
exports.make = make$1;
exports.decode = decode$1;
exports.label = label;
exports.Fragment = Fragment$1;
/* GraphQLConverter Not a pure module */
