// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Utils = require("../../../utils/Utils.bs.js");
var MlsPlans = require("./MlsPlans.bs.js");
var Property = require("./Property.bs.js");
var DateFnsTz = require("../../common/DateFns/DateFnsTz.bs.js");
var Core__JSON = require("@rescript/core/src/Core__JSON.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Array = require("@rescript/core/src/Core__Array.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var Core__Result = require("@rescript/core/src/Core__Result.bs.js");
var GraphQLConverter = require("../../common/GraphQLConverter.bs.js");

var Match = {};

function to_string(x) {
  if (x === "LOT") {
    return "Lot";
  } else if (x === "SINGLE_FAMILY") {
    return "Single-family";
  } else if (x === "MULTI_UNIT") {
    return "Multi unit";
  } else {
    return "Condo/townhouse";
  }
}

var PropertyType = {
  to_string: to_string
};

var Status = {};

function make(isPriceRange, price, minPrice, maxPrice) {
  if (isPriceRange !== undefined) {
    if (isPriceRange) {
      if (minPrice !== undefined && maxPrice !== undefined) {
        return {
                NAME: "PriceRange",
                VAL: [
                  Caml_option.valFromOption(minPrice),
                  Caml_option.valFromOption(maxPrice)
                ]
              };
      } else {
        return ;
      }
    } else if (price !== undefined) {
      return {
              NAME: "Price",
              VAL: Caml_option.valFromOption(price)
            };
    } else {
      return ;
    }
  } else if (price !== undefined) {
    return {
            NAME: "Price",
            VAL: Caml_option.valFromOption(price)
          };
  } else {
    return ;
  }
}

var Price = {
  make: make
};

function make$1(hideAddress, visibility, address) {
  if (hideAddress) {
    return {
            NAME: "Private",
            VAL: address
          };
  } else if (visibility !== undefined) {
    if (visibility === "PUBLIC") {
      if (address !== undefined) {
        return {
                NAME: "Public",
                VAL: Caml_option.valFromOption(address)
              };
      } else {
        return ;
      }
    } else if (visibility === "PRIVATE") {
      return {
              NAME: "Private",
              VAL: address
            };
    } else {
      return ;
    }
  } else {
    return ;
  }
}

var Address = {
  make: make$1
};

function decode(property) {
  var func = GraphQLConverter.Converter.runConverter;
  var arg = function (a, u) {
    return Property.DisplayLocation.make(a, u, property.displayNeighborhood);
  };
  var partial_arg = GraphQLConverter.Converter.some((function (obj) {
          return obj.userSelectedArea;
        }), "missing userSelectedArea");
  var param = function (param$1) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.flatMap((function (param) {
                                return GraphQLConverter.Converter.source(property, param);
                              }), (function (param) {
                                return GraphQLConverter.Converter.ok((function (obj) {
                                              return Core__Result.flatMap(Utils.Result.ofOption(Curry._1(GraphQLConverter.Converter.$$Error.make, "missing standardizedAddress"), obj.standardizedAddress), (function (i) {
                                                            return i;
                                                          }));
                                            }), param);
                              }), param);
                }), partial_arg, param$1);
  };
  return Curry._2(func, param, arg);
}

var ImprovedLocation = {
  decode: decode
};

function make$2(id, visibility, anonymousMatching, address, $$location, beds, baths, halfBaths, propertyType, price, numberOfUnits, lotSize, livingArea, description, mlsPlans, activeOnMls, status, statusChangeAt, commission, willShow, willAcceptOffers, createdAt, updatedAt, broadcastAt, statusChangeComment, soldPrice, hideSoldPrice, coordinates, timezone) {
  return {
          id: id,
          visibility: visibility,
          anonymousMatching: anonymousMatching,
          address: address,
          location: $$location,
          beds: beds,
          baths: baths,
          halfBaths: halfBaths,
          propertyType: propertyType,
          price: price,
          numberOfUnits: numberOfUnits,
          lotSize: lotSize,
          livingArea: livingArea,
          description: description,
          mlsPlans: mlsPlans,
          activeOnMls: activeOnMls,
          status: status === "SOLD" ? ({
                NAME: "SOLD",
                VAL: soldPrice
              }) : status,
          statusChangeAt: statusChangeAt,
          commission: commission,
          willShow: willShow,
          willAcceptOffers: willAcceptOffers,
          createdAt: createdAt,
          updatedAt: updatedAt,
          broadcastAt: broadcastAt,
          statusChangeComment: statusChangeComment,
          soldPrice: soldPrice,
          hideSoldPrice: hideSoldPrice,
          coordinates: coordinates,
          timezone: timezone
        };
}

function decode$1(obj) {
  var func = GraphQLConverter.Converter.runConverter;
  var partial_arg = GraphQLConverter.Converter.$$const(function (obj) {
        return Core__Option.flatMap(obj.coordinates, (function (coords) {
                      var match = coords.lon;
                      var match$1 = coords.lat;
                      if (match !== undefined && match$1 !== undefined) {
                        return {
                                lat: match$1,
                                lon: match
                              };
                      }
                      
                    }));
      });
  var partial_arg$1 = GraphQLConverter.Converter.$$const(function (obj) {
        return Core__Option.map(Core__Option.flatMap(obj.broadcastAt, Core__JSON.Decode.$$float), (function (prim) {
                      return new Date(prim);
                    }));
      });
  var partial_arg$2 = GraphQLConverter.Converter.some((function (obj) {
          return Core__Option.map(Core__Option.flatMap(obj.updatedAt, Core__JSON.Decode.$$float), (function (prim) {
                        return new Date(prim);
                      }));
        }), "missing updated at");
  var partial_arg$3 = GraphQLConverter.Converter.some((function (obj) {
          return Core__Option.map(Core__Option.flatMap(obj.createdAt, Core__JSON.Decode.$$float), (function (prim) {
                        return new Date(prim);
                      }));
        }), "missing created at");
  var partial_arg$4 = GraphQLConverter.Converter.some((function (obj) {
          return obj.status;
        }), "status was None");
  var partial_arg$5 = GraphQLConverter.Converter.some((function (obj) {
          return obj.description;
        }), "missing description");
  var partial_arg$6 = GraphQLConverter.Converter.some((function (obj) {
          return make(Core__Option.getOr(obj.priceRange, false), obj.price, obj.minPrice, obj.maxPrice);
        }), "Missing property type");
  var partial_arg$7 = GraphQLConverter.Converter.some((function (obj) {
          return obj.propertyType;
        }), "missing property type");
  var partial_arg$8 = GraphQLConverter.Converter.some((function (obj) {
          return make$1(Core__Option.getOr(obj.hideAddress, false), obj.visibility, obj.address);
        }), "Couldn't decode address");
  var partial_arg$9 = GraphQLConverter.Converter.some((function (obj) {
          return obj.visibility;
        }), "missing visibility");
  var partial_arg$10 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.flatMap((function (param) {
                                return GraphQLConverter.Converter.source(obj, param);
                              }), (function (param) {
                                return GraphQLConverter.Converter.ok((function (obj) {
                                              return {
                                                      TAG: "Ok",
                                                      _0: obj.id
                                                    };
                                            }), param);
                              }), param);
                }), partial_arg$9, param);
  };
  var partial_arg$11 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$10, (function (param) {
                  return GraphQLConverter.Converter.ok((function (obj) {
                                return {
                                        TAG: "Ok",
                                        _0: obj.anonymousMatching
                                      };
                              }), param);
                }), param);
  };
  var partial_arg$12 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$11, partial_arg$8, param);
  };
  var partial_arg$13 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$12, (function (param) {
                  return GraphQLConverter.Converter.ok(decode, param);
                }), param);
  };
  var partial_arg$14 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$13, (function (param) {
                  return GraphQLConverter.Converter.ok((function (obj) {
                                return {
                                        TAG: "Ok",
                                        _0: obj.beds
                                      };
                              }), param);
                }), param);
  };
  var partial_arg$15 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$14, (function (param) {
                  return GraphQLConverter.Converter.ok((function (obj) {
                                return {
                                        TAG: "Ok",
                                        _0: obj.baths
                                      };
                              }), param);
                }), param);
  };
  var partial_arg$16 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$15, (function (param) {
                  return GraphQLConverter.Converter.ok((function (obj) {
                                return {
                                        TAG: "Ok",
                                        _0: obj.halfBaths
                                      };
                              }), param);
                }), param);
  };
  var partial_arg$17 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$16, partial_arg$7, param);
  };
  var partial_arg$18 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$17, partial_arg$6, param);
  };
  var partial_arg$19 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$18, (function (param) {
                  return GraphQLConverter.Converter.ok((function (obj) {
                                return {
                                        TAG: "Ok",
                                        _0: obj.numberOfUnits
                                      };
                              }), param);
                }), param);
  };
  var partial_arg$20 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$19, (function (param) {
                  return GraphQLConverter.Converter.ok((function (obj) {
                                return {
                                        TAG: "Ok",
                                        _0: obj.lotSize
                                      };
                              }), param);
                }), param);
  };
  var partial_arg$21 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$20, (function (param) {
                  return GraphQLConverter.Converter.ok((function (obj) {
                                return {
                                        TAG: "Ok",
                                        _0: obj.livingArea
                                      };
                              }), param);
                }), param);
  };
  var partial_arg$22 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$21, partial_arg$5, param);
  };
  var partial_arg$23 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$22, (function (param) {
                  return GraphQLConverter.Converter.ok(MlsPlans.decodeFromJs, param);
                }), param);
  };
  var partial_arg$24 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$23, (function (param) {
                  return GraphQLConverter.Converter.ok((function (obj) {
                                var match = obj.visibility;
                                var match$1 = obj.activeOnMls;
                                if (match === "PRIVATE") {
                                  return {
                                          TAG: "Ok",
                                          _0: false
                                        };
                                }
                                if (match$1 !== undefined) {
                                  return {
                                          TAG: "Ok",
                                          _0: match$1
                                        };
                                } else {
                                  return {
                                          TAG: "Error",
                                          _0: GraphQLConverter.Helpers.makeError("activeOnMls was None")
                                        };
                                }
                              }), param);
                }), param);
  };
  var partial_arg$25 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$24, partial_arg$4, param);
  };
  var partial_arg$26 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$25, (function (param) {
                  return GraphQLConverter.Converter.ok((function (obj) {
                                var x = Core__Option.map(obj.statusChangeAt, GraphQLConverter.Helpers.decodeFloatDate);
                                if (x !== undefined) {
                                  if (x.TAG === "Ok") {
                                    return {
                                            TAG: "Ok",
                                            _0: Caml_option.some(x._0)
                                          };
                                  } else {
                                    return {
                                            TAG: "Error",
                                            _0: x._0
                                          };
                                  }
                                } else {
                                  return {
                                          TAG: "Ok",
                                          _0: undefined
                                        };
                                }
                              }), param);
                }), param);
  };
  var partial_arg$27 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$26, (function (param) {
                  return GraphQLConverter.Converter.ok((function (obj) {
                                return {
                                        TAG: "Ok",
                                        _0: Core__Option.getOr(obj.commission, "")
                                      };
                              }), param);
                }), param);
  };
  var partial_arg$28 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$27, (function (param) {
                  return GraphQLConverter.Converter.ok((function (obj) {
                                return {
                                        TAG: "Ok",
                                        _0: Core__Option.getOr(obj.willShow, false)
                                      };
                              }), param);
                }), param);
  };
  var partial_arg$29 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$28, (function (param) {
                  return GraphQLConverter.Converter.ok((function (obj) {
                                return {
                                        TAG: "Ok",
                                        _0: Core__Option.getOr(obj.willAcceptOffers, false)
                                      };
                              }), param);
                }), param);
  };
  var partial_arg$30 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$29, partial_arg$3, param);
  };
  var partial_arg$31 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$30, partial_arg$2, param);
  };
  var partial_arg$32 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$31, partial_arg$1, param);
  };
  var partial_arg$33 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$32, (function (param) {
                  return GraphQLConverter.Converter.ok((function (obj) {
                                return {
                                        TAG: "Ok",
                                        _0: obj.statusChangeComment
                                      };
                              }), param);
                }), param);
  };
  var partial_arg$34 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$33, (function (param) {
                  return GraphQLConverter.Converter.ok((function (obj) {
                                return {
                                        TAG: "Ok",
                                        _0: obj.soldPrice
                                      };
                              }), param);
                }), param);
  };
  var partial_arg$35 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$34, (function (param) {
                  return GraphQLConverter.Converter.ok((function (obj) {
                                return {
                                        TAG: "Ok",
                                        _0: obj.hideSoldPrice
                                      };
                              }), param);
                }), param);
  };
  var partial_arg$36 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$35, partial_arg, param);
  };
  return Curry._2(func, (function (param) {
                return GraphQLConverter.Helpers.flatMap(partial_arg$36, (function (param) {
                              return GraphQLConverter.Converter.ok((function (obj) {
                                            return GraphQLConverter.Helpers.decodeRequired("chapter was None", (function (chapter) {
                                                          return GraphQLConverter.Helpers.decodeRequired("timeZone was None", (function (v) {
                                                                        return {
                                                                                TAG: "Ok",
                                                                                _0: DateFnsTz.Timezone.make(v)
                                                                              };
                                                                      }), chapter.timeZone);
                                                        }), obj.chapter);
                                          }), param);
                            }), param);
              }), make$2);
}

var BasicInfo = {
  make: make$2,
  decode: decode$1
};

var Property$1 = {
  PropertyType: PropertyType,
  Status: Status,
  Price: Price,
  Address: Address,
  ImprovedLocation: ImprovedLocation,
  BasicInfo: BasicInfo
};

var ShowingType = {};

var empty = {
  singleFamily: false,
  multiUnit: false,
  condoTownhouse: false,
  lot: false
};

function update(state, action) {
  switch (action) {
    case "ToggleSingleFamily" :
        return {
                singleFamily: !state.singleFamily,
                multiUnit: state.multiUnit,
                condoTownhouse: state.condoTownhouse,
                lot: state.lot
              };
    case "ToggleMultiUnit" :
        return {
                singleFamily: state.singleFamily,
                multiUnit: !state.multiUnit,
                condoTownhouse: state.condoTownhouse,
                lot: state.lot
              };
    case "ToggleCondoTownhouse" :
        return {
                singleFamily: state.singleFamily,
                multiUnit: state.multiUnit,
                condoTownhouse: !state.condoTownhouse,
                lot: state.lot
              };
    case "ToggleLot" :
        return {
                singleFamily: state.singleFamily,
                multiUnit: state.multiUnit,
                condoTownhouse: state.condoTownhouse,
                lot: !state.lot
              };
    
  }
}

function toArray(param) {
  var map = function (bValue, variant) {
    if (bValue) {
      return Caml_option.some(variant);
    }
    
  };
  return Core__Array.filterMap([
              map(param.singleFamily, "SINGLE_FAMILY"),
              map(param.multiUnit, "MULTI_UNIT"),
              map(param.condoTownhouse, "CONDO"),
              map(param.lot, "LOT")
            ], (function (x) {
                return x;
              }));
}

function ofArray(array) {
  return Core__Array.reduce(array, empty, (function (acc, pType) {
                if (pType === "LOT") {
                  return {
                          singleFamily: acc.singleFamily,
                          multiUnit: acc.multiUnit,
                          condoTownhouse: acc.condoTownhouse,
                          lot: true
                        };
                } else if (pType === "SINGLE_FAMILY") {
                  return {
                          singleFamily: true,
                          multiUnit: acc.multiUnit,
                          condoTownhouse: acc.condoTownhouse,
                          lot: acc.lot
                        };
                } else if (pType === "MULTI_UNIT") {
                  return {
                          singleFamily: acc.singleFamily,
                          multiUnit: true,
                          condoTownhouse: acc.condoTownhouse,
                          lot: acc.lot
                        };
                } else {
                  return {
                          singleFamily: acc.singleFamily,
                          multiUnit: acc.multiUnit,
                          condoTownhouse: true,
                          lot: acc.lot
                        };
                }
              }));
}

var PropertyTypes = {
  empty: empty,
  update: update,
  toArray: toArray,
  ofArray: ofArray
};

var PropertyAttributes$p;

var Visibility;

var Contact;

var PropertyAttributes;

exports.PropertyAttributes$p = PropertyAttributes$p;
exports.Match = Match;
exports.Visibility = Visibility;
exports.Contact = Contact;
exports.PropertyAttributes = PropertyAttributes;
exports.Property = Property$1;
exports.ShowingType = ShowingType;
exports.PropertyTypes = PropertyTypes;
/* Utils Not a pure module */
