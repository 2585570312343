// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml = require("rescript/lib/js/caml.js");
var Curry = require("rescript/lib/js/curry.js");
var Utils = require("../../../utils/Utils.bs.js");
var Js_exn = require("rescript/lib/js/js_exn.js");
var Sentry = require("../../Sentry.bs.js");
var Belt_Id = require("rescript/lib/js/belt_Id.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Js_array = require("rescript/lib/js/js_array.js");
var MlsPlans = require("../types/MlsPlans.bs.js");
var Property = require("../types/Property.bs.js");
var Core__List = require("@rescript/core/src/Core__List.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Array = require("@rescript/core/src/Core__Array.bs.js");
var Types__User = require("../../user/Types__User.bs.js");
var DateFnsTz = require("date-fns-tz");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var Core__Result = require("@rescript/core/src/Core__Result.bs.js");
var GraphQLHelpers = require("../../common/GraphQL/GraphQLHelpers.bs.js");
var Types__Clients = require("../types/Types__Clients.bs.js");
var GraphQLConverter = require("../../common/GraphQLConverter.bs.js");

function result_of_option(msg, opt) {
  if (opt !== undefined) {
    return {
            TAG: "Ok",
            _0: Caml_option.valFromOption(opt)
          };
  } else {
    return {
            TAG: "Error",
            _0: msg
          };
  }
}

function accumulate_error(result, errors) {
  if (result.TAG === "Ok") {
    return errors;
  } else {
    return {
            hd: result._0,
            tl: errors
          };
  }
}

function decode_property_showing(obj) {
  var startDate = result_of_option("Couldn't decode createdAt", Core__Option.map(obj.startDate, (function (x) {
              return new Date(x);
            })));
  var endDate = result_of_option("couldn't decode updatedAt", Core__Option.map(obj.endDate, (function (x) {
              return new Date(x);
            })));
  var errors = accumulate_error(endDate, accumulate_error(startDate, /* [] */0));
  if (startDate.TAG === "Ok" && endDate.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              id: obj.id,
              startDate: startDate._0,
              endDate: endDate._0
            }
          };
  } else {
    return {
            TAG: "Error",
            _0: errors
          };
  }
}

function decode_property(obj) {
  var timezone = Core__Option.getOr(Core__Option.flatMap(obj.chapter, (function (x) {
              return x.timeZone;
            })), "utc");
  var showings = Core__Array.filterMap(obj.showings.map(function (x) {
              var showing = decode_property_showing(x);
              if (showing.TAG === "Ok") {
                return showing._0;
              }
              Sentry.captureErrorMessage("error decoding Showings in ShowingsQuery", true, true, "ShowingsQuery.res", 112, showing._0, undefined, undefined);
            }), (function (x) {
            return x;
          })).map(function (showing) {
        return {
                id: showing.id,
                startDate: DateFnsTz.utcToZonedTime(showing.startDate, timezone),
                endDate: DateFnsTz.utcToZonedTime(showing.endDate, timezone)
              };
      });
  var agent = obj.agent;
  var address = result_of_option("couldn't decode address", Curry._3(Types__Clients.Property.Address.make, Core__Option.getOr(obj.hideAddress, false), obj.visibility, obj.address));
  var $$location = Utils.Result.mapError((function (error) {
          return Core__List.toArray(Curry._1(GraphQLConverter.Converter.$$Error.errors, error)).join(", ");
        }), obj.location);
  var mlsPlans = MlsPlans.decodeFromJs(obj);
  var activeOnMls = Utils.Result.ofOption("activeOnMls was None", obj.activeOnMls);
  var error;
  if (address.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: address._0
          };
  }
  if ($$location.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: $$location._0
          };
  }
  if (activeOnMls.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: activeOnMls._0
          };
  }
  if (agent.TAG === "Ok") {
    if (mlsPlans.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                id: obj.id,
                showings: showings,
                address: address._0,
                location: $$location._0,
                agent: agent._0,
                timezone: timezone,
                mlsPlans: mlsPlans._0,
                activeOnMls: activeOnMls._0
              }
            };
    }
    error = mlsPlans._0;
  } else {
    error = agent._0;
  }
  return {
          TAG: "Error",
          _0: Core__List.toArray(Curry._1(GraphQLConverter.Converter.$$Error.errors, error)).join(", ")
        };
}

function decode_showing(obj) {
  var startDate = result_of_option("Couldn't decode createdAt", Core__Option.map(obj.startDate, (function (x) {
              return new Date(x);
            })));
  var endDate = result_of_option("couldn't decode updatedAt", Core__Option.map(obj.endDate, (function (x) {
              return new Date(x);
            })));
  var type_ = result_of_option("couldn't decode showing type", obj.type_);
  var property = Core__Result.flatMap(result_of_option("couln't get property", obj.property), decode_property);
  var errors = accumulate_error(property, accumulate_error(type_, accumulate_error(endDate, accumulate_error(startDate, /* [] */0))));
  if (startDate.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: errors
          };
  }
  if (endDate.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: errors
          };
  }
  if (type_.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: errors
          };
  }
  if (property.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: errors
          };
  }
  var property$1 = property._0;
  return {
          TAG: "Ok",
          _0: {
            id: obj.id,
            startDate: DateFnsTz.utcToZonedTime(startDate._0, property$1.timezone),
            endDate: DateFnsTz.utcToZonedTime(endDate._0, property$1.timezone),
            notes: obj.notes,
            type_: type_._0,
            property: property$1
          }
        };
}

function cmp(a, b) {
  return Caml.float_compare(a.getTime(), b.getTime());
}

var DateCmp = Belt_Id.MakeComparable({
      cmp: cmp
    });

function decodeAgent(param) {
  return GraphQLConverter.Helpers.decodeRequired("agent was None", (function (i) {
                return i;
              }), param);
}

var ppx_printed_query = "fragment showing on Showing   {\nid  \nstartDate  \nendDate  \nnotes  \ntype_: type  \nproperty  {\nid  \naddress  \nhideAddress  \nvisibility  \n..." + Property.DisplayLocation.PropertyDisplayLocation.name + "   \nonMlsAt  \ngoingOnMls  \nactiveOnMls  \nmlsListingType  \nwillShow  \nwillAcceptOffers  \nshowings  {\nid  \nstartDate  \nendDate  \n}\n\nchapter  {\nid  \ntimeZone  \n}\n\nagent  {\n..." + Types__User.Fragment.User.name + "   \n}\n\n}\n\n}\n" + Property.DisplayLocation.PropertyDisplayLocation.query + Types__User.Fragment.User.query;

function parse(value) {
  var value$1 = value["startDate"];
  var value$2 = value["endDate"];
  var value$3 = value["notes"];
  var value$4 = value["type_"];
  var tmp;
  if (value$4 == null) {
    tmp = undefined;
  } else {
    var tmp$1;
    switch (value$4) {
      case "BUYERS_WELCOME" :
          tmp$1 = "BUYERS_WELCOME";
          break;
      case "TAN_ONLY" :
          tmp$1 = "TAN_ONLY";
          break;
      default:
        tmp$1 = "AGENTS_ONLY";
    }
    tmp = tmp$1;
  }
  var value$5 = value["property"];
  var tmp$2;
  if (value$5 == null) {
    tmp$2 = undefined;
  } else {
    var value$6 = value$5["address"];
    var value$7 = value$5["hideAddress"];
    var value$8 = value$5["visibility"];
    var tmp$3;
    if (value$8 == null) {
      tmp$3 = undefined;
    } else {
      var tmp$4 = value$8 === "PRIVATE" ? "PRIVATE" : "PUBLIC";
      tmp$3 = tmp$4;
    }
    var value$9 = value$5["onMlsAt"];
    var value$10 = value$5["goingOnMls"];
    var value$11 = value$5["activeOnMls"];
    var value$12 = value$5["mlsListingType"];
    var tmp$5;
    if (value$12 == null) {
      tmp$5 = undefined;
    } else {
      var tmp$6;
      switch (value$12) {
        case "ACTIVE" :
            tmp$6 = "ACTIVE";
            break;
        case "COMING_SOON" :
            tmp$6 = "COMING_SOON";
            break;
        default:
          tmp$6 = "OFF_MLS";
      }
      tmp$5 = tmp$6;
    }
    var value$13 = value$5["willShow"];
    var value$14 = value$5["willAcceptOffers"];
    var value$15 = value$5["showings"];
    var value$16 = value$5["chapter"];
    var tmp$7;
    if (value$16 == null) {
      tmp$7 = undefined;
    } else {
      var value$17 = Js_json.decodeObject(value$16);
      var tmp$8;
      if (value$17 !== undefined) {
        var value$18 = Js_dict.get(value$17, "id");
        var field_id = value$18 !== undefined ? value$18 : Js_exn.raiseError("Unexpected GraphQL query response");
        var value$19 = Js_dict.get(value$17, "timeZone");
        var field_timeZone = value$19 !== undefined && !(value$19 == null) ? value$19 : undefined;
        tmp$8 = {
          id: field_id,
          timeZone: field_timeZone
        };
      } else {
        tmp$8 = Js_exn.raiseError("Unexpected GraphQL query response");
      }
      tmp$7 = tmp$8;
    }
    var value$20 = value$5["agent"];
    tmp$2 = {
      id: value$5["id"],
      address: !(value$6 == null) ? value$6 : undefined,
      hideAddress: !(value$7 == null) ? value$7 : undefined,
      visibility: tmp$3,
      location: Curry._1(Property.DisplayLocation.PropertyDisplayLocation.parse, value$5),
      onMlsAt: !(value$9 == null) ? value$9 : undefined,
      goingOnMls: !(value$10 == null) ? value$10 : undefined,
      activeOnMls: !(value$11 == null) ? value$11 : undefined,
      mlsListingType: tmp$5,
      willShow: !(value$13 == null) ? value$13 : undefined,
      willAcceptOffers: !(value$14 == null) ? value$14 : undefined,
      showings: GraphQLHelpers.defaultToEmptyArray(!(value$15 == null) ? Js_array.map((function (value) {
                    if (value == null) {
                      return ;
                    }
                    var value$1 = value["startDate"];
                    var value$2 = value["endDate"];
                    return {
                            id: value["id"],
                            startDate: !(value$1 == null) ? value$1 : undefined,
                            endDate: !(value$2 == null) ? value$2 : undefined
                          };
                  }), value$15) : undefined),
      chapter: tmp$7,
      agent: decodeAgent(!(value$20 == null) ? Curry._1(Types__User.Fragment.User.parse, value$20) : undefined)
    };
  }
  return decode_showing({
              id: value["id"],
              startDate: !(value$1 == null) ? value$1 : undefined,
              endDate: !(value$2 == null) ? value$2 : undefined,
              notes: !(value$3 == null) ? value$3 : undefined,
              type_: tmp,
              property: tmp$2
            });
}

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var Showing = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: "showing",
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var Fragment = {
  Showing: Showing
};

var defaultToEmptyArray = GraphQLHelpers.defaultToEmptyArray;

exports.result_of_option = result_of_option;
exports.accumulate_error = accumulate_error;
exports.decode_property_showing = decode_property_showing;
exports.decode_property = decode_property;
exports.decode_showing = decode_showing;
exports.DateCmp = DateCmp;
exports.defaultToEmptyArray = defaultToEmptyArray;
exports.decodeAgent = decodeAgent;
exports.Fragment = Fragment;
/* DateCmp Not a pure module */
