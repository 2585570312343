// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Utils = require("../../../utils/Utils.bs.js");
var Loader = require("../../../uikit/reason/atoms/Loader/Loader.bs.js");
var Styled = require("./Styled.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Js_array = require("rescript/lib/js/js_array.js");
var View__Jsx3 = require("../../../uikit/reason/helpers/View__Jsx3.bs.js");
var ApolloHooks = require("reason-apollo-hooks/src/ApolloHooks.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Array = require("@rescript/core/src/Core__Array.bs.js");
var CurrentUser = require("../../../reason/common/CurrentUser/CurrentUser.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var ErrorComponent = require("../../../reason/common/Error/ErrorComponent.bs.js");
var ReadMoreButton = require("../../../components/buttons/ReadMoreButton.bs.js");
var TANApolloHooks = require("../../../reason/utils/apollo/TANApolloHooks.bs.js");
var NotificationRow = require("./NotificationRow.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var NotificationsUtils = require("../../../v5/common/notifications/NotificationsUtils.bs.js");
var NotificationWidgetRelay = require("./NotificationWidgetRelay.bs.js");

function NotificationWidgetWithData$NotificationGroup(props) {
  var markAsRead = props.markAsRead;
  return JsxRuntime.jsxs(View__Jsx3.make, {
              children: [
                JsxRuntime.jsx(Styled.GroupTitle.make, {
                      children: props.title
                    }),
                JsxRuntime.jsx(View__Jsx3.make, {
                      children: Caml_option.some(props.notifications.map(function (notification) {
                                return JsxRuntime.jsx(NotificationRow.make, {
                                            notification: notification,
                                            markAsRead: markAsRead
                                          }, notification.id);
                              }))
                    })
              ]
            });
}

var NotificationGroup = {
  make: NotificationWidgetWithData$NotificationGroup
};

function NotificationWidgetWithData$NotificationWidget(props) {
  var __showMore = props.showMore;
  var markAsRead = props.markAsRead;
  var showMore = __showMore !== undefined ? __showMore : true;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(View__Jsx3.make, {
                      mb: {
                        NAME: "px",
                        VAL: 30
                      },
                      children: Caml_option.some(NotificationsUtils.NotificationGrouper.groupNotifications(props.notifications, (function (n) {
                                    return n.createdAt;
                                  })).map(function (x) {
                                var groupTime = x[0];
                                return JsxRuntime.jsx(NotificationWidgetWithData$NotificationGroup, {
                                            title: NotificationsUtils.NotificationGrouper.toString(groupTime),
                                            notifications: x[1],
                                            markAsRead: markAsRead
                                          }, NotificationsUtils.NotificationGrouper.toString(groupTime));
                              }))
                    }),
                showMore ? JsxRuntime.jsx(ReadMoreButton.make, {
                        href: "/notifications",
                        children: "View All"
                      }) : null
              ]
            });
}

var NotificationWidget = {
  make: NotificationWidgetWithData$NotificationWidget
};

var ppx_printed_query = "mutation markAsRead($input: MarkNotificationReadInput!)  {\nmarkNotificationReadByCurrentUser(input: $input)  {\nnotification  {\nid  \nstatus  \n}\n\n}\n\n}\n";

function parse(value) {
  var value$1 = value["markNotificationReadByCurrentUser"];
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1["notification"];
    var tmp$1;
    if (value$2 == null) {
      tmp$1 = undefined;
    } else {
      var value$3 = value$2["status"];
      tmp$1 = {
        id: value$2["id"],
        status: !(value$3 == null) ? value$3 : undefined
      };
    }
    tmp = {
      notification: tmp$1
    };
  }
  return {
          markNotificationReadByCurrentUser: tmp
        };
}

function json_of_MarkNotificationReadInput(value) {
  return Js_dict.fromArray(Js_array.filter((function (param) {
                    return !Js_json.test(param[1], "Null");
                  }), [[
                    "id",
                    value.id
                  ]]));
}

function make(input, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray(Js_array.filter((function (param) {
                      return !Js_json.test(param[1], "Null");
                    }), [[
                      "input",
                      json_of_MarkNotificationReadInput(input)
                    ]])),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var input = variables.input;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray(Js_array.filter((function (param) {
                      return !Js_json.test(param[1], "Null");
                    }), [[
                      "input",
                      json_of_MarkNotificationReadInput(input)
                    ]])),
          parse: parse
        };
}

function makeVariables(input, param) {
  return Js_dict.fromArray(Js_array.filter((function (param) {
                    return !Js_json.test(param[1], "Null");
                  }), [[
                    "input",
                    json_of_MarkNotificationReadInput(input)
                  ]]));
}

function definition_2(graphql_ppx_use_json_variables_fn, input, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray(Js_array.filter((function (param) {
                        return !Js_json.test(param[1], "Null");
                      }), [[
                        "input",
                        json_of_MarkNotificationReadInput(input)
                      ]])));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var MarkAsRead = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  json_of_MarkNotificationReadInput: json_of_MarkNotificationReadInput,
  make: make,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

function arrayDecode(x) {
  return Core__Array.filterMap(Core__Option.getOr(x, []), (function (x) {
                return Core__Option.flatMap(x, (function (x) {
                              return Utils.Result.toOption(x);
                            }));
              }));
}

var ppx_printed_query$1 = "query Notifications($limit: Int)  {\nnotificationsForCurrentUser(limit: $limit)  {\n..." + NotificationRow.Fragments.NotificationWidget__Notification__WithDrafts.name + "   \n}\n\n}\n" + NotificationRow.Fragments.NotificationWidget__Notification__WithDrafts.query;

function parse$1(value) {
  var value$1 = value["notificationsForCurrentUser"];
  return {
          notificationsForCurrentUser: arrayDecode(!(value$1 == null) ? Js_array.map((function (value) {
                        if (!(value == null)) {
                          return Curry._1(NotificationRow.Fragments.NotificationWidget__Notification__WithDrafts.parse, value);
                        }
                        
                      }), value$1) : undefined)
        };
}

function make$1(limit, param) {
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray(Js_array.filter((function (param) {
                      return !Js_json.test(param[1], "Null");
                    }), [[
                      "limit",
                      limit !== undefined ? limit : null
                    ]])),
          parse: parse$1
        };
}

function makeWithVariables$1(variables) {
  var limit = variables.limit;
  return {
          query: ppx_printed_query$1,
          variables: Js_dict.fromArray(Js_array.filter((function (param) {
                      return !Js_json.test(param[1], "Null");
                    }), [[
                      "limit",
                      limit !== undefined ? limit : null
                    ]])),
          parse: parse$1
        };
}

function makeVariables$1(limit, param) {
  return Js_dict.fromArray(Js_array.filter((function (param) {
                    return !Js_json.test(param[1], "Null");
                  }), [[
                    "limit",
                    limit !== undefined ? limit : null
                  ]]));
}

function definition_2$1(graphql_ppx_use_json_variables_fn, limit, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray(Js_array.filter((function (param) {
                        return !Js_json.test(param[1], "Null");
                      }), [[
                        "limit",
                        limit !== undefined ? limit : null
                      ]])));
}

var definition$1 = [
  parse$1,
  ppx_printed_query$1,
  definition_2$1
];

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var Query = {
  arrayDecode: arrayDecode,
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  make: make$1,
  makeWithVariables: makeWithVariables$1,
  makeVariables: makeVariables$1,
  definition: definition$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

function NotificationWidgetWithData$NotificationWidgetWithData(props) {
  var match = TANApolloHooks.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, definition);
  var mutate = match[0];
  var isImpersonated = CurrentUser.useIsImpersonated();
  var markAsRead = function (id) {
    if (isImpersonated) {
      return ;
    } else {
      Curry._6(mutate, makeVariables({
                id: id
              }, undefined), undefined, undefined, undefined, undefined, undefined);
      return ;
    }
  };
  var match$1 = ApolloHooks.useQuery(undefined, makeVariables$1(props.limit, undefined), undefined, "CacheAndNetwork", undefined, undefined, undefined, undefined, definition$1);
  var full = match$1[1];
  var response = full.data;
  if (response === undefined) {
    if (full.loading) {
      return JsxRuntime.jsx(View__Jsx3.make, {
                  pb: "md",
                  children: Caml_option.some(JsxRuntime.jsx(Loader.make, {}))
                });
    } else {
      return JsxRuntime.jsx(ErrorComponent.make, {
                  message: "An error occurred while loading your data. Please refresh or try again later..."
                });
    }
  }
  if (full.error !== undefined) {
    return JsxRuntime.jsx(ErrorComponent.make, {
                message: "An error occurred while loading your data. Please refresh or try again later..."
              });
  }
  var notifications = Caml_option.valFromOption(response).notificationsForCurrentUser;
  if (notifications.length !== 0) {
    return JsxRuntime.jsx(NotificationWidgetWithData$NotificationWidget, {
                notifications: notifications,
                markAsRead: markAsRead,
                showMore: props.showMore
              });
  } else {
    return null;
  }
}

var NotificationWidgetWithData = {
  make: NotificationWidgetWithData$NotificationWidgetWithData
};

var NotificationGrouper;

var $$default = NotificationWidgetRelay.make;

exports.NotificationGrouper = NotificationGrouper;
exports.NotificationGroup = NotificationGroup;
exports.NotificationWidget = NotificationWidget;
exports.MarkAsRead = MarkAsRead;
exports.Query = Query;
exports.NotificationWidgetWithData = NotificationWidgetWithData;
exports.default = $$default;
exports.__esModule = true;
/* Utils Not a pure module */
