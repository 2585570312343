import styled from 'styled-components'

import { mediaBreakpointUp } from '../../../styles/media'

export const GroupTitle = styled.h3`
  display: none;
  border-bottom: 1px solid ${props => props.theme.colors.gallery};
  font-weight: 500;
  padding-bottom: 1rem;
  margin-bottom: 0;
  font-size: 22px;
  line-height: 1;
  margin-top: 1rem;
  ${mediaBreakpointUp('md')`
    display: block;
  `};
  color: var(--color-tuatara);
`
