// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Js_exn = require("rescript/lib/js/js_exn.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var AreaSummary = require("../../reason/clients/graphql/AreaSummary.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var GraphQLConverter = require("../../reason/common/GraphQLConverter.bs.js");

function defaultToEmptyContent(x) {
  return Core__Option.mapOr(x, {
              streetAddress: undefined,
              userSelectedArea: undefined
            }, (function (x) {
                return x.VAL;
              }));
}

function decodeListingId(v) {
  return Core__Option.map(v, (function (x) {
                return x.VAL.id;
              }));
}

function contentToString(draft) {
  var content = draft.content;
  var $$location = Core__Option.map(content.userSelectedArea, (function (param) {
          return param.name;
        }));
  var match = content.streetAddress;
  if (match !== undefined) {
    if ($$location !== undefined) {
      return match + (", " + $$location);
    } else {
      return match;
    }
  } else if ($$location !== undefined) {
    return $$location;
  } else {
    return "(no address entered)";
  }
}

function decode(obj) {
  var match = obj.draftType;
  var match$1 = obj.listingId;
  var partial_arg = GraphQLConverter.Helpers.ok(match === "REBROADCAST" ? (
          match$1 !== undefined ? ({
                TAG: "Ok",
                _0: {
                  NAME: "REBROADCAST",
                  VAL: match$1
                }
              }) : ({
                TAG: "Error",
                _0: GraphQLConverter.Helpers.makeError("listingId was None in draft != NEW")
              })
        ) : (
          match === "NEW" ? ({
                TAG: "Ok",
                _0: "NEW"
              }) : (
              match$1 !== undefined ? ({
                    TAG: "Ok",
                    _0: {
                      NAME: "BROADCAST_QUIET_SELLER",
                      VAL: match$1
                    }
                  }) : ({
                    TAG: "Error",
                    _0: GraphQLConverter.Helpers.makeError("listingId was None in draft != NEW")
                  })
            )
        ));
  var partial_arg$1 = GraphQLConverter.Helpers.ok(obj.updatedAt);
  var partial_arg$2 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.source(obj, param);
                }), partial_arg$1, param);
  };
  return GraphQLConverter.Helpers.run(function (updatedAt, draftType) {
                return {
                        id: obj.id,
                        updatedAt: updatedAt,
                        content: obj.content,
                        draftType: draftType
                      };
              })(function (param) {
              return GraphQLConverter.Helpers.flatMap(partial_arg$2, partial_arg, param);
            });
}

var ppx_printed_query = "fragment DraftSummary on Draft   {\nid  \nupdatedAt  \ndraftType: type  \nlistingId: listing  {\n__typename\n...on Property   {\nid  \n}\n\n...on RentalProperty   {\nid  \n}\n\n}\n\ncontent  {\n__typename\n...on DraftProperty   {\nstreetAddress  \nuserSelectedArea  {\n..." + AreaSummary.AreaSummary.name + "   \n}\n\n}\n\n}\n\n}\n" + AreaSummary.AreaSummary.query;

function parse(value) {
  var value$1 = Js_json.decodeObject(value);
  var tmp;
  if (value$1 !== undefined) {
    var value$2 = Js_dict.get(value$1, "id");
    var field_id = value$2 !== undefined ? value$2 : Js_exn.raiseError("Unexpected GraphQL query response");
    var value$3 = Js_dict.get(value$1, "updatedAt");
    var field_updatedAt = value$3 !== undefined ? GraphQLConverter.Helpers.parseUTCDateFromFloat(value$3) : Js_exn.raiseError("Unexpected GraphQL query response");
    var value$4 = Js_dict.get(value$1, "draftType");
    var field_draftType;
    if (value$4 !== undefined) {
      switch (value$4) {
        case "NEW" :
            field_draftType = "NEW";
            break;
        case "REBROADCAST" :
            field_draftType = "REBROADCAST";
            break;
        default:
          field_draftType = "BROADCAST_QUIET_SELLER";
      }
    } else {
      field_draftType = Js_exn.raiseError("Unexpected GraphQL query response");
    }
    var value$5 = Js_dict.get(value$1, "listingId");
    var field_listingId;
    if (value$5 !== undefined) {
      var tmp$1;
      if (value$5 == null) {
        tmp$1 = undefined;
      } else {
        var typename_obj = Js_json.decodeObject(value$5);
        var tmp$2;
        if (typename_obj !== undefined) {
          var typename = Js_dict.get(typename_obj, "__typename");
          if (typename !== undefined) {
            var typename$1 = Js_json.decodeString(typename);
            if (typename$1 !== undefined) {
              switch (typename$1) {
                case "Property" :
                    tmp$2 = {
                      NAME: "Property",
                      VAL: {
                        id: value$5["id"]
                      }
                    };
                    break;
                case "RentalProperty" :
                    tmp$2 = {
                      NAME: "RentalProperty",
                      VAL: {
                        id: value$5["id"]
                      }
                    };
                    break;
                default:
                  tmp$2 = Js_exn.raiseError("Unexpected GraphQL query response");
              }
            } else {
              tmp$2 = Js_exn.raiseError("Unexpected GraphQL query response");
            }
          } else {
            tmp$2 = Js_exn.raiseError("Unexpected GraphQL query response");
          }
        } else {
          tmp$2 = Js_exn.raiseError("Unexpected GraphQL query response");
        }
        tmp$1 = tmp$2;
      }
      field_listingId = decodeListingId(tmp$1);
    } else {
      field_listingId = Js_exn.raiseError("Unexpected GraphQL query response");
    }
    var value$6 = Js_dict.get(value$1, "content");
    var field_content;
    if (value$6 !== undefined) {
      var tmp$3;
      if (value$6 == null) {
        tmp$3 = undefined;
      } else {
        var typename_obj$1 = Js_json.decodeObject(value$6);
        var tmp$4;
        if (typename_obj$1 !== undefined) {
          var typename$2 = Js_dict.get(typename_obj$1, "__typename");
          if (typename$2 !== undefined) {
            var typename$3 = Js_json.decodeString(typename$2);
            if (typename$3 === "DraftProperty") {
              var value$7 = Js_json.decodeObject(value$6);
              var tmp$5;
              if (value$7 !== undefined) {
                var value$8 = Js_dict.get(value$7, "streetAddress");
                var field_streetAddress = value$8 !== undefined && !(value$8 == null) ? value$8 : undefined;
                var value$9 = Js_dict.get(value$7, "userSelectedArea");
                var field_userSelectedArea = value$9 !== undefined && !(value$9 == null) ? AreaSummary.AreaSummary.parse(value$9) : undefined;
                tmp$5 = {
                  streetAddress: field_streetAddress,
                  userSelectedArea: field_userSelectedArea
                };
              } else {
                tmp$5 = Js_exn.raiseError("Unexpected GraphQL query response");
              }
              tmp$4 = {
                NAME: "DraftProperty",
                VAL: tmp$5
              };
            } else {
              tmp$4 = Js_exn.raiseError("Unexpected GraphQL query response");
            }
          } else {
            tmp$4 = Js_exn.raiseError("Unexpected GraphQL query response");
          }
        } else {
          tmp$4 = Js_exn.raiseError("Unexpected GraphQL query response");
        }
        tmp$3 = tmp$4;
      }
      field_content = defaultToEmptyContent(tmp$3);
    } else {
      field_content = Js_exn.raiseError("Unexpected GraphQL query response");
    }
    tmp = {
      id: field_id,
      updatedAt: field_updatedAt,
      content: field_content,
      draftType: field_draftType,
      listingId: field_listingId
    };
  } else {
    tmp = Js_exn.raiseError("Unexpected GraphQL query response");
  }
  return decode(tmp);
}

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var DraftSummary = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: "DraftSummary",
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var Fragment;

exports.defaultToEmptyContent = defaultToEmptyContent;
exports.decodeListingId = decodeListingId;
exports.contentToString = contentToString;
exports.decode = decode;
exports.DraftSummary = DraftSummary;
exports.Fragment = Fragment;
/* GraphQLConverter Not a pure module */
