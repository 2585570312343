// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var ListingAMLData = {};

var ClientNeedAMLData = {};

function getId(x) {
  switch (x.TAG) {
    case "PropertyAgentMatchLog" :
    case "RentalPropertyAgentMatchLog" :
    case "BuyerNeedAgentMatchLog" :
    case "RentalNeedAgentMatchLog" :
        return x._0.id;
    
  }
}

var AML = {
  ListingAMLData: ListingAMLData,
  ClientNeedAMLData: ClientNeedAMLData,
  getId: getId
};

var Showing = {};

function make(id, createdAt, source, status) {
  return {
          id: id,
          createdAt: createdAt,
          source: source,
          status: status
        };
}

var $$Notification = {
  make: make
};

exports.AML = AML;
exports.Showing = Showing;
exports.$$Notification = $$Notification;
/* No side effect */
