// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Styled = require("./styled");

var make = Styled.GroupTitle;

var GroupTitle = {
  make: make
};

exports.GroupTitle = GroupTitle;
/* make Not a pure module */
