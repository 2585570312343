// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Client = require("../../../reason/clients/types/Client.bs.js");
var Js_exn = require("rescript/lib/js/js_exn.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var UTCDate = require("../../../reason/common/types/UTCDate.bs.js");
var Js_array = require("rescript/lib/js/js_array.js");
var Property = require("../../../reason/clients/types/Property.bs.js");
var ViewStack = require("../../../reason/common/ViewStack.bs.js");
var Core__List = require("@rescript/core/src/Core__List.bs.js");
var Icon__Jsx3 = require("../../../uikit/reason/atoms/Icon/Icon__Jsx3.bs.js");
var MediaQuery = require("../../../reason/common/Media/MediaQuery.bs.js");
var Responsive = require("../../../uikit/reason/helpers/Responsive.bs.js");
var Text__Jsx3 = require("../../../uikit/reason/helpers/Text__Jsx3.bs.js");
var View__Jsx3 = require("../../../uikit/reason/helpers/View__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CurrentUser = require("../../../reason/common/CurrentUser/CurrentUser.bs.js");
var Next__Atoms = require("../../../reason/next/Next__Atoms.bs.js");
var Types__User = require("../../../reason/user/Types__User.bs.js");
var AvatarSrcUrl = require("../../../utils/AvatarSrcUrl.bs.js");
var Avatar__Jsx3 = require("../../../uikit/reason/atoms/Avatar/Avatar__Jsx3.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var Core__Result = require("@rescript/core/src/Core__Result.bs.js");
var DraftSummary = require("../../../components/MyDraftsWidget/DraftSummary.bs.js");
var Theme__Color = require("../../../reason/common/Theme/Theme__Color.bs.js");
var ShowingsQuery = require("../../../reason/clients/graphql/ShowingsQuery.bs.js");
var RentalProperty = require("../../../reason/clients/types/RentalProperty.bs.js");
var GraphQLConverter = require("../../../reason/common/GraphQLConverter.bs.js");
var NotificationText = require("./NotificationText.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Notification__Types = require("./Notification__Types.bs.js");
var Colors = require("uikit/styles/colors");
var NotificationTypeIcon = require("./NotificationTypeIcon");

function decodeListingMatch(match_) {
  return GraphQLConverter.Helpers.decodeRequired("source was none on match " + match_.id, (function (source) {
                return GraphQLConverter.Helpers.decodeRequired("client was none on client need " + source.id, (function (c) {
                              return Core__Result.map(c, (function (c) {
                                            return {
                                                    client: c
                                                  };
                                          }));
                            }), source.client);
              }), match_.source);
}

function decodeClientNeedMatch(match_) {
  return GraphQLConverter.Helpers.decodeRequired("source none on match " + match_.id, (function (s) {
                var partial_arg = GraphQLConverter.Helpers.ok(s.address);
                var partial_arg$1 = GraphQLConverter.Helpers.someOk(s.client, "client was none on property " + s.id);
                var partial_arg$2 = function (param) {
                  return GraphQLConverter.Helpers.flatMap((function (param) {
                                return GraphQLConverter.Helpers.source(s, param);
                              }), partial_arg$1, param);
                };
                return GraphQLConverter.Helpers.run(function (client, address) {
                              return {
                                      address: address,
                                      client: client
                                    };
                            })(function (param) {
                            return GraphQLConverter.Helpers.flatMap(partial_arg$2, partial_arg, param);
                          });
              }), match_.source);
}

var ppx_printed_query = "fragment MatchForBuyerNeed__Fragment on MatchForBuyerNeed   {\nid  \nsource  {\nid  \nclient  {\n..." + Client.Fragment.DecodedClient.name + "   \n}\n\n}\n\n}\n" + Client.Fragment.DecodedClient.query;

function parse(value) {
  var value$1 = value["source"];
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1["client"];
    tmp = {
      id: value$1["id"],
      client: !(value$2 == null) ? Curry._1(Client.Fragment.DecodedClient.parse, value$2) : undefined
    };
  }
  return decodeListingMatch({
              id: value["id"],
              source: tmp
            });
}

var name = "MatchForBuyerNeed__Fragment";

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var MatchForBuyerNeed__Fragment = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  name: name,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

var ppx_printed_query$1 = "fragment MatchForRentalNeed__Fragment on MatchForRentalNeed   {\nid  \nsource  {\nid  \nclient  {\n..." + Client.Fragment.DecodedClient.name + "   \n}\n\n}\n\n}\n" + Client.Fragment.DecodedClient.query;

function parse$1(value) {
  var value$1 = value["source"];
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1["client"];
    tmp = {
      id: value$1["id"],
      client: !(value$2 == null) ? Curry._1(Client.Fragment.DecodedClient.parse, value$2) : undefined
    };
  }
  return decodeListingMatch({
              id: value["id"],
              source: tmp
            });
}

var name$1 = "MatchForRentalNeed__Fragment";

function ret_type$1(f) {
  return {};
}

var MT_Ret$1 = {};

var MatchForRentalNeed__Fragment = {
  ppx_printed_query: ppx_printed_query$1,
  query: ppx_printed_query$1,
  parse: parse$1,
  name: name$1,
  ret_type: ret_type$1,
  MT_Ret: MT_Ret$1
};

var ppx_printed_query$2 = "fragment MatchForProperty__Fragment on MatchForProperty   {\nid  \nsource  {\nid  \n..." + Property.Address.Address.name + "   \nclient  {\n..." + Client.Fragment.DecodedClient.name + "   \n}\n\n}\n\n}\n" + Client.Fragment.DecodedClient.query + Property.Address.Address.query;

function parse$2(value) {
  var value$1 = value["source"];
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1["client"];
    tmp = {
      id: value$1["id"],
      address: Curry._1(Property.Address.Address.parse, value$1),
      client: !(value$2 == null) ? Curry._1(Client.Fragment.DecodedClient.parse, value$2) : undefined
    };
  }
  return decodeClientNeedMatch({
              id: value["id"],
              source: tmp
            });
}

var name$2 = "MatchForProperty__Fragment";

function ret_type$2(f) {
  return {};
}

var MT_Ret$2 = {};

var MatchForProperty__Fragment = {
  ppx_printed_query: ppx_printed_query$2,
  query: ppx_printed_query$2,
  parse: parse$2,
  name: name$2,
  ret_type: ret_type$2,
  MT_Ret: MT_Ret$2
};

var ppx_printed_query$3 = "fragment MatchForRentalProperty__Fragment on MatchForRentalProperty   {\nid  \nsource  {\nid  \n..." + RentalProperty.RentalAddress.RentalAddress.name + "   \nclient  {\n..." + Client.Fragment.DecodedClient.name + "   \n}\n\n}\n\n}\n" + Client.Fragment.DecodedClient.query + RentalProperty.RentalAddress.RentalAddress.query;

function parse$3(value) {
  var value$1 = value["source"];
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1["client"];
    tmp = {
      id: value$1["id"],
      address: Curry._1(RentalProperty.RentalAddress.RentalAddress.parse, value$1),
      client: !(value$2 == null) ? Curry._1(Client.Fragment.DecodedClient.parse, value$2) : undefined
    };
  }
  return decodeClientNeedMatch({
              id: value["id"],
              source: tmp
            });
}

var name$3 = "MatchForRentalProperty__Fragment";

function ret_type$3(f) {
  return {};
}

var MT_Ret$3 = {};

var MatchForRentalProperty__Fragment = {
  ppx_printed_query: ppx_printed_query$3,
  query: ppx_printed_query$3,
  parse: parse$3,
  name: name$3,
  ret_type: ret_type$3,
  MT_Ret: MT_Ret$3
};

var MatchFragments = {
  decodeListingMatch: decodeListingMatch,
  decodeClientNeedMatch: decodeClientNeedMatch,
  MatchForBuyerNeed__Fragment: MatchForBuyerNeed__Fragment,
  MatchForRentalNeed__Fragment: MatchForRentalNeed__Fragment,
  MatchForProperty__Fragment: MatchForProperty__Fragment,
  MatchForRentalProperty__Fragment: MatchForRentalProperty__Fragment
};

function decodeAmlListingData(obj) {
  var partial_arg = "a match was none on aml " + obj.id;
  var partial_arg$1 = function (param) {
    return GraphQLConverter.Helpers.decodeRequired(partial_arg, (function (match_) {
                  if (typeof match_ === "object") {
                    return match_.VAL;
                  } else {
                    return {
                            TAG: "Error",
                            _0: Curry._1(GraphQLConverter.Converter.$$Error.make, "Unexpected type of match on AML " + obj.id)
                          };
                  }
                }), param);
  };
  var partial_arg$2 = GraphQLConverter.Helpers.ok(GraphQLConverter.Helpers.decodeRequired("existing matches array was none", (function (param) {
              return GraphQLConverter.Helpers.decodeArray(partial_arg$1, param);
            }), obj.existingMatches));
  var partial_arg$3 = "a match was none on aml " + obj.id;
  var partial_arg$4 = function (param) {
    return GraphQLConverter.Helpers.decodeRequired(partial_arg$3, (function (match_) {
                  if (typeof match_ === "object") {
                    return match_.VAL;
                  } else {
                    return {
                            TAG: "Error",
                            _0: Curry._1(GraphQLConverter.Converter.$$Error.make, "Unexpected type of match on AML " + obj.id)
                          };
                  }
                }), param);
  };
  var partial_arg$5 = GraphQLConverter.Helpers.ok(GraphQLConverter.Helpers.decodeRequired("matches array was none", (function (param) {
              return GraphQLConverter.Helpers.decodeArray(partial_arg$4, param);
            }), obj.newMatches));
  var partial_arg$6 = GraphQLConverter.Helpers.ok(GraphQLConverter.Helpers.decodeRequired("on AML " + (obj.id + " source was none"), (function (source) {
              return Types__User.decodeMaybeAnonymousUser(source.agent);
            }), obj.source));
  var partial_arg$7 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.source(obj, param);
                }), partial_arg$6, param);
  };
  var partial_arg$8 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$7, partial_arg$5, param);
  };
  return GraphQLConverter.Helpers.run(function (sourceOwner, newMatches, existingMatches) {
                return {
                        id: obj.id,
                        sourceOwner: sourceOwner,
                        newMatches: newMatches,
                        existingMatches: existingMatches,
                        eventType: obj.listingEventType
                      };
              })(function (param) {
              return GraphQLConverter.Helpers.flatMap(partial_arg$8, partial_arg$2, param);
            });
}

var ppx_printed_query$4 = "fragment propertyAgentMatchLog on PropertyAgentMatchLog   {\nid  \nsource  {\nid  \nagent  {\n..." + Types__User.Fragment.User.name + "   \n}\n\n}\n\nnewMatches  {\n__typename\n...on MatchForBuyerNeed   {\n..." + name + "   \n}\n\n}\n\nexistingMatches  {\n__typename\n...on MatchForBuyerNeed   {\n..." + name + "   \n}\n\n}\n\nlistingEventType: eventType  \n}\n" + ppx_printed_query + Types__User.Fragment.User.query;

function parse$4(value) {
  var value$1 = value["source"];
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1["agent"];
    tmp = {
      id: value$1["id"],
      agent: !(value$2 == null) ? Curry._1(Types__User.Fragment.User.parse, value$2) : undefined
    };
  }
  var value$3 = value["newMatches"];
  var value$4 = value["existingMatches"];
  var value$5 = value["listingEventType"];
  var tmp$1;
  if (value$5 == null) {
    tmp$1 = undefined;
  } else {
    var tmp$2;
    switch (value$5) {
      case "BROADCAST_LISTING_CREATED" :
          tmp$2 = "BROADCAST_LISTING_CREATED";
          break;
      case "LISTING_REBROADCASTED" :
          tmp$2 = "LISTING_REBROADCASTED";
          break;
      case "PRIVATE_LISTING_CREATED" :
          tmp$2 = "PRIVATE_LISTING_CREATED";
          break;
      case "QUIET_SELLER_BROADCASTED" :
          tmp$2 = "QUIET_SELLER_BROADCASTED";
          break;
      default:
        tmp$2 = "LISTING_EDITED";
    }
    tmp$1 = tmp$2;
  }
  return decodeAmlListingData({
              id: value["id"],
              source: tmp,
              newMatches: !(value$3 == null) ? Js_array.map((function (value) {
                        if (value == null) {
                          return ;
                        }
                        var typename_obj = Js_json.decodeObject(value);
                        var tmp;
                        if (typename_obj !== undefined) {
                          var typename = Js_dict.get(typename_obj, "__typename");
                          if (typename !== undefined) {
                            var typename$1 = Js_json.decodeString(typename);
                            tmp = typename$1 !== undefined ? (
                                typename$1 === "MatchForBuyerNeed" ? ({
                                      NAME: "MatchForBuyerNeed",
                                      VAL: parse(value)
                                    }) : "Nonexhaustive"
                              ) : Js_exn.raiseError("Unexpected GraphQL query response");
                          } else {
                            tmp = Js_exn.raiseError("Unexpected GraphQL query response");
                          }
                        } else {
                          tmp = Js_exn.raiseError("Unexpected GraphQL query response");
                        }
                        return tmp;
                      }), value$3) : undefined,
              existingMatches: !(value$4 == null) ? Js_array.map((function (value) {
                        if (value == null) {
                          return ;
                        }
                        var typename_obj = Js_json.decodeObject(value);
                        var tmp;
                        if (typename_obj !== undefined) {
                          var typename = Js_dict.get(typename_obj, "__typename");
                          if (typename !== undefined) {
                            var typename$1 = Js_json.decodeString(typename);
                            tmp = typename$1 !== undefined ? (
                                typename$1 === "MatchForBuyerNeed" ? ({
                                      NAME: "MatchForBuyerNeed",
                                      VAL: parse(value)
                                    }) : "Nonexhaustive"
                              ) : Js_exn.raiseError("Unexpected GraphQL query response");
                          } else {
                            tmp = Js_exn.raiseError("Unexpected GraphQL query response");
                          }
                        } else {
                          tmp = Js_exn.raiseError("Unexpected GraphQL query response");
                        }
                        return tmp;
                      }), value$4) : undefined,
              listingEventType: tmp$1
            });
}

var name$4 = "propertyAgentMatchLog";

function ret_type$4(f) {
  return {};
}

var MT_Ret$4 = {};

var PropertyAgentMatchLog = {
  ppx_printed_query: ppx_printed_query$4,
  query: ppx_printed_query$4,
  parse: parse$4,
  name: name$4,
  ret_type: ret_type$4,
  MT_Ret: MT_Ret$4
};

var Fragment = {
  PropertyAgentMatchLog: PropertyAgentMatchLog
};

var PropertyAML = {
  Fragment: Fragment
};

var ppx_printed_query$5 = "fragment rentalPropertyAgentMatchLog on RentalPropertyAgentMatchLog   {\nid  \nsource  {\nid  \nagent  {\n..." + Types__User.Fragment.User.name + "   \n}\n\n}\n\nnewMatches  {\n__typename\n...on MatchForRentalNeed   {\n..." + name$1 + "   \n}\n\n}\n\nexistingMatches  {\n__typename\n...on MatchForRentalNeed   {\n..." + name$1 + "   \n}\n\n}\n\nlistingEventType: eventType  \n}\n" + ppx_printed_query$1 + Types__User.Fragment.User.query;

function parse$5(value) {
  var value$1 = value["source"];
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1["agent"];
    tmp = {
      id: value$1["id"],
      agent: !(value$2 == null) ? Curry._1(Types__User.Fragment.User.parse, value$2) : undefined
    };
  }
  var value$3 = value["newMatches"];
  var value$4 = value["existingMatches"];
  var value$5 = value["listingEventType"];
  var tmp$1;
  if (value$5 == null) {
    tmp$1 = undefined;
  } else {
    var tmp$2;
    switch (value$5) {
      case "BROADCAST_LISTING_CREATED" :
          tmp$2 = "BROADCAST_LISTING_CREATED";
          break;
      case "LISTING_REBROADCASTED" :
          tmp$2 = "LISTING_REBROADCASTED";
          break;
      case "PRIVATE_LISTING_CREATED" :
          tmp$2 = "PRIVATE_LISTING_CREATED";
          break;
      case "QUIET_SELLER_BROADCASTED" :
          tmp$2 = "QUIET_SELLER_BROADCASTED";
          break;
      default:
        tmp$2 = "LISTING_EDITED";
    }
    tmp$1 = tmp$2;
  }
  return decodeAmlListingData({
              id: value["id"],
              source: tmp,
              newMatches: !(value$3 == null) ? Js_array.map((function (value) {
                        if (value == null) {
                          return ;
                        }
                        var typename_obj = Js_json.decodeObject(value);
                        var tmp;
                        if (typename_obj !== undefined) {
                          var typename = Js_dict.get(typename_obj, "__typename");
                          if (typename !== undefined) {
                            var typename$1 = Js_json.decodeString(typename);
                            tmp = typename$1 !== undefined ? (
                                typename$1 === "MatchForRentalNeed" ? ({
                                      NAME: "MatchForRentalNeed",
                                      VAL: parse$1(value)
                                    }) : "Nonexhaustive"
                              ) : Js_exn.raiseError("Unexpected GraphQL query response");
                          } else {
                            tmp = Js_exn.raiseError("Unexpected GraphQL query response");
                          }
                        } else {
                          tmp = Js_exn.raiseError("Unexpected GraphQL query response");
                        }
                        return tmp;
                      }), value$3) : undefined,
              existingMatches: !(value$4 == null) ? Js_array.map((function (value) {
                        if (value == null) {
                          return ;
                        }
                        var typename_obj = Js_json.decodeObject(value);
                        var tmp;
                        if (typename_obj !== undefined) {
                          var typename = Js_dict.get(typename_obj, "__typename");
                          if (typename !== undefined) {
                            var typename$1 = Js_json.decodeString(typename);
                            tmp = typename$1 !== undefined ? (
                                typename$1 === "MatchForRentalNeed" ? ({
                                      NAME: "MatchForRentalNeed",
                                      VAL: parse$1(value)
                                    }) : "Nonexhaustive"
                              ) : Js_exn.raiseError("Unexpected GraphQL query response");
                          } else {
                            tmp = Js_exn.raiseError("Unexpected GraphQL query response");
                          }
                        } else {
                          tmp = Js_exn.raiseError("Unexpected GraphQL query response");
                        }
                        return tmp;
                      }), value$4) : undefined,
              listingEventType: tmp$1
            });
}

var name$5 = "rentalPropertyAgentMatchLog";

function ret_type$5(f) {
  return {};
}

var MT_Ret$5 = {};

var RentalPropertyAgentMatchLog = {
  ppx_printed_query: ppx_printed_query$5,
  query: ppx_printed_query$5,
  parse: parse$5,
  name: name$5,
  ret_type: ret_type$5,
  MT_Ret: MT_Ret$5
};

var Fragment$1 = {
  RentalPropertyAgentMatchLog: RentalPropertyAgentMatchLog
};

var RentalPropertyAML = {
  Fragment: Fragment$1
};

function decodeAMLSourceAgent(obj) {
  return GraphQLConverter.Helpers.decodeRequired("on AML " + (obj.id + " source was none"), (function (source) {
                return GraphQLConverter.Helpers.decodeRequired("agent was none", (function (agent) {
                              return agent;
                            }), source.agent);
              }), obj.source);
}

function decodeClientNeedAMLData(obj) {
  var partial_arg = "a match was none on aml " + obj.id;
  var partial_arg$1 = function (param) {
    return GraphQLConverter.Helpers.decodeRequired(partial_arg, (function (match_) {
                  if (typeof match_ === "object") {
                    return match_.VAL;
                  } else {
                    return {
                            TAG: "Error",
                            _0: Curry._1(GraphQLConverter.Converter.$$Error.make, "Unexpected type of match on AML " + obj.id)
                          };
                  }
                }), param);
  };
  var partial_arg$2 = GraphQLConverter.Helpers.ok(GraphQLConverter.Helpers.decodeRequired("existing matches array was none", (function (param) {
              return GraphQLConverter.Helpers.decodeArray(partial_arg$1, param);
            }), obj.existingMatches));
  var partial_arg$3 = "a match was none on aml " + obj.id;
  var partial_arg$4 = function (param) {
    return GraphQLConverter.Helpers.decodeRequired(partial_arg$3, (function (match_) {
                  if (typeof match_ === "object") {
                    return match_.VAL;
                  } else {
                    return {
                            TAG: "Error",
                            _0: Curry._1(GraphQLConverter.Converter.$$Error.make, "Unexpected type of match on AML " + obj.id)
                          };
                  }
                }), param);
  };
  var partial_arg$5 = GraphQLConverter.Helpers.ok(GraphQLConverter.Helpers.decodeRequired("matches array was none on aml " + obj.id, (function (param) {
              return GraphQLConverter.Helpers.decodeArray(partial_arg$4, param);
            }), obj.newMatches));
  var partial_arg$6 = GraphQLConverter.Helpers.ok(decodeAMLSourceAgent(obj));
  var partial_arg$7 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.source(obj, param);
                }), partial_arg$6, param);
  };
  var partial_arg$8 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$7, partial_arg$5, param);
  };
  return GraphQLConverter.Helpers.run(function (sourceOwner, newMatches, existingMatches) {
                return {
                        id: obj.id,
                        sourceOwner: sourceOwner,
                        newMatches: newMatches,
                        existingMatches: existingMatches,
                        eventType: obj.clientNeedEventType
                      };
              })(function (param) {
              return GraphQLConverter.Helpers.flatMap(partial_arg$8, partial_arg$2, param);
            });
}

var ppx_printed_query$6 = "fragment rentalNeedAgentMatchLog on RentalNeedAgentMatchLog   {\nid  \nsource  {\nagent  {\n..." + Types__User.Fragment.User.name + "   \n}\n\n}\n\nnewMatches  {\n__typename\n...on MatchForRentalProperty   {\n..." + name$3 + "   \n}\n\n}\n\nexistingMatches  {\n__typename\n...on MatchForRentalProperty   {\n..." + name$3 + "   \n}\n\n}\n\nclientNeedEventType: eventType  \n}\n" + ppx_printed_query$3 + Types__User.Fragment.User.query;

function parse$6(value) {
  var value$1 = value["source"];
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1["agent"];
    tmp = {
      agent: !(value$2 == null) ? Curry._1(Types__User.Fragment.User.parse, value$2) : undefined
    };
  }
  var value$3 = value["newMatches"];
  var value$4 = value["existingMatches"];
  var value$5 = value["clientNeedEventType"];
  var tmp$1;
  if (value$5 == null) {
    tmp$1 = undefined;
  } else {
    var tmp$2;
    switch (value$5) {
      case "BROADCAST_CLIENT_NEED_CREATED" :
          tmp$2 = "BROADCAST_CLIENT_NEED_CREATED";
          break;
      case "PRIVATE_CLIENT_NEED_CREATED" :
          tmp$2 = "PRIVATE_CLIENT_NEED_CREATED";
          break;
      case "QUIET_BUYER_BROADCASTED" :
          tmp$2 = "QUIET_BUYER_BROADCASTED";
          break;
      default:
        tmp$2 = "CLIENT_NEED_EDITED";
    }
    tmp$1 = tmp$2;
  }
  return decodeClientNeedAMLData({
              id: value["id"],
              source: tmp,
              newMatches: !(value$3 == null) ? Js_array.map((function (value) {
                        if (value == null) {
                          return ;
                        }
                        var typename_obj = Js_json.decodeObject(value);
                        var tmp;
                        if (typename_obj !== undefined) {
                          var typename = Js_dict.get(typename_obj, "__typename");
                          if (typename !== undefined) {
                            var typename$1 = Js_json.decodeString(typename);
                            tmp = typename$1 !== undefined ? (
                                typename$1 === "MatchForRentalProperty" ? ({
                                      NAME: "MatchForRentalProperty",
                                      VAL: parse$3(value)
                                    }) : "Nonexhaustive"
                              ) : Js_exn.raiseError("Unexpected GraphQL query response");
                          } else {
                            tmp = Js_exn.raiseError("Unexpected GraphQL query response");
                          }
                        } else {
                          tmp = Js_exn.raiseError("Unexpected GraphQL query response");
                        }
                        return tmp;
                      }), value$3) : undefined,
              existingMatches: !(value$4 == null) ? Js_array.map((function (value) {
                        if (value == null) {
                          return ;
                        }
                        var typename_obj = Js_json.decodeObject(value);
                        var tmp;
                        if (typename_obj !== undefined) {
                          var typename = Js_dict.get(typename_obj, "__typename");
                          if (typename !== undefined) {
                            var typename$1 = Js_json.decodeString(typename);
                            tmp = typename$1 !== undefined ? (
                                typename$1 === "MatchForRentalProperty" ? ({
                                      NAME: "MatchForRentalProperty",
                                      VAL: parse$3(value)
                                    }) : "Nonexhaustive"
                              ) : Js_exn.raiseError("Unexpected GraphQL query response");
                          } else {
                            tmp = Js_exn.raiseError("Unexpected GraphQL query response");
                          }
                        } else {
                          tmp = Js_exn.raiseError("Unexpected GraphQL query response");
                        }
                        return tmp;
                      }), value$4) : undefined,
              clientNeedEventType: tmp$1
            });
}

var name$6 = "rentalNeedAgentMatchLog";

function ret_type$6(f) {
  return {};
}

var MT_Ret$6 = {};

var RentalNeedAgentMatchLog = {
  ppx_printed_query: ppx_printed_query$6,
  query: ppx_printed_query$6,
  parse: parse$6,
  name: name$6,
  ret_type: ret_type$6,
  MT_Ret: MT_Ret$6
};

var Fragment$2 = {
  RentalNeedAgentMatchLog: RentalNeedAgentMatchLog
};

var RentalNeedAML = {
  decodeClientNeedAMLData: decodeClientNeedAMLData,
  Fragment: Fragment$2
};

function decode(obj) {
  var partial_arg = "a match was none on aml " + obj.id;
  var partial_arg$1 = function (param) {
    return GraphQLConverter.Helpers.decodeRequired(partial_arg, (function (match_) {
                  if (typeof match_ === "object") {
                    return match_.VAL;
                  } else {
                    return {
                            TAG: "Error",
                            _0: Curry._1(GraphQLConverter.Converter.$$Error.make, "Unexpected type of match on AML " + obj.id)
                          };
                  }
                }), param);
  };
  var partial_arg$2 = GraphQLConverter.Helpers.ok(GraphQLConverter.Helpers.decodeRequired("existing matches array was none", (function (param) {
              return GraphQLConverter.Helpers.decodeArray(partial_arg$1, param);
            }), obj.existingMatches));
  var partial_arg$3 = "a match was none on aml " + obj.id;
  var partial_arg$4 = function (param) {
    return GraphQLConverter.Helpers.decodeRequired(partial_arg$3, (function (match_) {
                  if (typeof match_ === "object") {
                    return match_.VAL;
                  } else {
                    return {
                            TAG: "Error",
                            _0: Curry._1(GraphQLConverter.Converter.$$Error.make, "Unexpected type of match on AML " + obj.id)
                          };
                  }
                }), param);
  };
  var partial_arg$5 = GraphQLConverter.Helpers.ok(GraphQLConverter.Helpers.decodeRequired("matches array was none on aml " + obj.id, (function (param) {
              return GraphQLConverter.Helpers.decodeArray(partial_arg$4, param);
            }), obj.newMatches));
  var partial_arg$6 = GraphQLConverter.Helpers.ok(decodeAMLSourceAgent(obj));
  var partial_arg$7 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Helpers.source(obj, param);
                }), partial_arg$6, param);
  };
  var partial_arg$8 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$7, partial_arg$5, param);
  };
  return GraphQLConverter.Helpers.run(function (sourceOwner, newMatches, existingMatches) {
                return {
                        id: obj.id,
                        sourceOwner: sourceOwner,
                        newMatches: newMatches,
                        existingMatches: existingMatches,
                        eventType: obj.clientNeedEventType
                      };
              })(function (param) {
              return GraphQLConverter.Helpers.flatMap(partial_arg$8, partial_arg$2, param);
            });
}

var ppx_printed_query$7 = "fragment buyerNeedAgentMatchLog on BuyerNeedAgentMatchLog   {\nid  \nsource  {\nid  \nagent  {\n..." + Types__User.Fragment.User.name + "   \n}\n\n}\n\nnewMatches  {\n__typename\n...on MatchForProperty   {\n..." + name$2 + "   \n}\n\n}\n\nexistingMatches  {\n__typename\n...on MatchForProperty   {\n..." + name$2 + "   \n}\n\n}\n\nclientNeedEventType: eventType  \n}\n" + ppx_printed_query$2 + Types__User.Fragment.User.query;

function parse$7(value) {
  var value$1 = value["source"];
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1["agent"];
    tmp = {
      id: value$1["id"],
      agent: !(value$2 == null) ? Curry._1(Types__User.Fragment.User.parse, value$2) : undefined
    };
  }
  var value$3 = value["newMatches"];
  var value$4 = value["existingMatches"];
  var value$5 = value["clientNeedEventType"];
  var tmp$1;
  if (value$5 == null) {
    tmp$1 = undefined;
  } else {
    var tmp$2;
    switch (value$5) {
      case "BROADCAST_CLIENT_NEED_CREATED" :
          tmp$2 = "BROADCAST_CLIENT_NEED_CREATED";
          break;
      case "PRIVATE_CLIENT_NEED_CREATED" :
          tmp$2 = "PRIVATE_CLIENT_NEED_CREATED";
          break;
      case "QUIET_BUYER_BROADCASTED" :
          tmp$2 = "QUIET_BUYER_BROADCASTED";
          break;
      default:
        tmp$2 = "CLIENT_NEED_EDITED";
    }
    tmp$1 = tmp$2;
  }
  return decode({
              id: value["id"],
              source: tmp,
              newMatches: !(value$3 == null) ? Js_array.map((function (value) {
                        if (value == null) {
                          return ;
                        }
                        var typename_obj = Js_json.decodeObject(value);
                        var tmp;
                        if (typename_obj !== undefined) {
                          var typename = Js_dict.get(typename_obj, "__typename");
                          if (typename !== undefined) {
                            var typename$1 = Js_json.decodeString(typename);
                            tmp = typename$1 !== undefined ? (
                                typename$1 === "MatchForProperty" ? ({
                                      NAME: "MatchForProperty",
                                      VAL: parse$2(value)
                                    }) : "Nonexhaustive"
                              ) : Js_exn.raiseError("Unexpected GraphQL query response");
                          } else {
                            tmp = Js_exn.raiseError("Unexpected GraphQL query response");
                          }
                        } else {
                          tmp = Js_exn.raiseError("Unexpected GraphQL query response");
                        }
                        return tmp;
                      }), value$3) : undefined,
              existingMatches: !(value$4 == null) ? Js_array.map((function (value) {
                        if (value == null) {
                          return ;
                        }
                        var typename_obj = Js_json.decodeObject(value);
                        var tmp;
                        if (typename_obj !== undefined) {
                          var typename = Js_dict.get(typename_obj, "__typename");
                          if (typename !== undefined) {
                            var typename$1 = Js_json.decodeString(typename);
                            tmp = typename$1 !== undefined ? (
                                typename$1 === "MatchForProperty" ? ({
                                      NAME: "MatchForProperty",
                                      VAL: parse$2(value)
                                    }) : "Nonexhaustive"
                              ) : Js_exn.raiseError("Unexpected GraphQL query response");
                          } else {
                            tmp = Js_exn.raiseError("Unexpected GraphQL query response");
                          }
                        } else {
                          tmp = Js_exn.raiseError("Unexpected GraphQL query response");
                        }
                        return tmp;
                      }), value$4) : undefined,
              clientNeedEventType: tmp$1
            });
}

var name$7 = "buyerNeedAgentMatchLog";

function ret_type$7(f) {
  return {};
}

var MT_Ret$7 = {};

var BuyerNeedAgentMatchLog = {
  ppx_printed_query: ppx_printed_query$7,
  query: ppx_printed_query$7,
  parse: parse$7,
  name: name$7,
  ret_type: ret_type$7,
  MT_Ret: MT_Ret$7
};

var Fragment$3 = {
  BuyerNeedAgentMatchLog: BuyerNeedAgentMatchLog
};

var BuyerNeedAML = {
  decode: decode,
  Fragment: Fragment$3
};

function sourceDecoder(s) {
  return Core__Option.getOr(Core__Option.map(s, (function (x) {
                    var variant = x.NAME;
                    if (variant === "Message") {
                      return {
                              TAG: "Error",
                              _0: GraphQLConverter.Helpers.makeError("received message " + (x.VAL.id + ", expected draft"))
                            };
                    }
                    if (variant === "Draft") {
                      return Core__Result.map(x.VAL, (function (d) {
                                    return {
                                            TAG: "DraftSummary",
                                            _0: d
                                          };
                                  }));
                    }
                    if (variant === "RentalPropertyAgentMatchLog") {
                      return Core__Result.map(x.VAL, (function (aml) {
                                    return {
                                            TAG: "AgentMatchLog",
                                            _0: {
                                              TAG: "RentalPropertyAgentMatchLog",
                                              _0: aml
                                            }
                                          };
                                  }));
                    }
                    if (variant === "BuyerNeedAgentMatchLog") {
                      return Core__Result.map(x.VAL, (function (aml) {
                                    return {
                                            TAG: "AgentMatchLog",
                                            _0: {
                                              TAG: "BuyerNeedAgentMatchLog",
                                              _0: aml
                                            }
                                          };
                                  }));
                    }
                    if (variant === "RentalNeedAgentMatchLog") {
                      return Core__Result.map(x.VAL, (function (aml) {
                                    return {
                                            TAG: "AgentMatchLog",
                                            _0: {
                                              TAG: "RentalNeedAgentMatchLog",
                                              _0: aml
                                            }
                                          };
                                  }));
                    }
                    if (variant === "PropertyAgentMatchLog") {
                      return Core__Result.map(x.VAL, (function (aml) {
                                    return {
                                            TAG: "AgentMatchLog",
                                            _0: {
                                              TAG: "PropertyAgentMatchLog",
                                              _0: aml
                                            }
                                          };
                                  }));
                    }
                    var s = x.VAL;
                    if (s.TAG === "Ok") {
                      return {
                              TAG: "Ok",
                              _0: {
                                TAG: "Showing",
                                _0: s._0
                              }
                            };
                    } else {
                      return {
                              TAG: "Error",
                              _0: Curry._2(GraphQLConverter.Converter.$$Error.concat, Curry._1(GraphQLConverter.Converter.$$Error.make, "Error decoding Showings"), Curry._1(GraphQLConverter.Converter.$$Error.fromArray, Core__List.toArray(s._0)))
                            };
                    }
                  })), {
              TAG: "Error",
              _0: Curry._1(GraphQLConverter.Converter.$$Error.make, "source was None")
            });
}

function decode$1(obj) {
  var func = GraphQLConverter.Converter.runConverter;
  var arg = Notification__Types.$$Notification.make;
  var partial_arg = GraphQLConverter.Converter.some((function (obj) {
          return obj.status;
        }), "Status was None");
  var partial_arg$1 = GraphQLConverter.Converter.some((function (obj) {
          return Core__Option.flatMap(Core__Option.map(obj.createdAt, UTCDate.decode), (function (x) {
                        if (x.TAG === "Ok") {
                          return Caml_option.some(x._0);
                        }
                        
                      }));
        }), "createdAt not defined");
  var partial_arg$2 = GraphQLConverter.Converter.$$const(function (obj) {
        return obj.id;
      });
  var partial_arg$3 = function (param) {
    return GraphQLConverter.Helpers.flatMap((function (param) {
                  return GraphQLConverter.Converter.source(obj, param);
                }), partial_arg$2, param);
  };
  var partial_arg$4 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$3, partial_arg$1, param);
  };
  var partial_arg$5 = function (param) {
    return GraphQLConverter.Helpers.flatMap(partial_arg$4, (function (param) {
                  return GraphQLConverter.Converter.ok((function (obj) {
                                return sourceDecoder(obj.source);
                              }), param);
                }), param);
  };
  return GraphQLConverter.Utils.reportErrors("Error decoding Notification", "NotificationRow.res", 414, (function (param) {
                  return Curry._2(func, param, arg);
                })(function (param) {
                  return GraphQLConverter.Helpers.flatMap(partial_arg$5, partial_arg, param);
                }));
}

var ppx_printed_query$8 = "fragment NotificationWidget__Notification__WithDrafts on Notification   {\nid  \ncreatedAt  \nsource(returnDrafts: true)  {\n__typename\n...on Showing   {\n..." + ShowingsQuery.Fragment.Showing.name + "   \n}\n\n...on Message   {\nid  \n}\n\n...on Draft   {\n..." + DraftSummary.DraftSummary.name + "   \n}\n\n...on PropertyAgentMatchLog   {\n..." + name$4 + "   \n}\n\n...on RentalPropertyAgentMatchLog   {\n..." + name$5 + "   \n}\n\n...on BuyerNeedAgentMatchLog   {\n..." + name$7 + "   \n}\n\n...on RentalNeedAgentMatchLog   {\n..." + name$6 + "   \n}\n\n}\n\nstatus  \n}\n" + ppx_printed_query$7 + DraftSummary.DraftSummary.query + ppx_printed_query$4 + ppx_printed_query$6 + ppx_printed_query$5 + ShowingsQuery.Fragment.Showing.query;

function parse$8(value) {
  var value$1 = value["createdAt"];
  var value$2 = value["source"];
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var typename_obj = Js_json.decodeObject(value$2);
    var tmp$1;
    if (typename_obj !== undefined) {
      var typename = Js_dict.get(typename_obj, "__typename");
      if (typename !== undefined) {
        var typename$1 = Js_json.decodeString(typename);
        if (typename$1 !== undefined) {
          switch (typename$1) {
            case "BuyerNeedAgentMatchLog" :
                tmp$1 = {
                  NAME: "BuyerNeedAgentMatchLog",
                  VAL: parse$7(value$2)
                };
                break;
            case "Draft" :
                tmp$1 = {
                  NAME: "Draft",
                  VAL: DraftSummary.DraftSummary.parse(value$2)
                };
                break;
            case "Message" :
                tmp$1 = {
                  NAME: "Message",
                  VAL: {
                    id: value$2["id"]
                  }
                };
                break;
            case "PropertyAgentMatchLog" :
                tmp$1 = {
                  NAME: "PropertyAgentMatchLog",
                  VAL: parse$4(value$2)
                };
                break;
            case "RentalNeedAgentMatchLog" :
                tmp$1 = {
                  NAME: "RentalNeedAgentMatchLog",
                  VAL: parse$6(value$2)
                };
                break;
            case "RentalPropertyAgentMatchLog" :
                tmp$1 = {
                  NAME: "RentalPropertyAgentMatchLog",
                  VAL: parse$5(value$2)
                };
                break;
            case "Showing" :
                tmp$1 = {
                  NAME: "Showing",
                  VAL: Curry._1(ShowingsQuery.Fragment.Showing.parse, value$2)
                };
                break;
            default:
              tmp$1 = Js_exn.raiseError("Unexpected GraphQL query response");
          }
        } else {
          tmp$1 = Js_exn.raiseError("Unexpected GraphQL query response");
        }
      } else {
        tmp$1 = Js_exn.raiseError("Unexpected GraphQL query response");
      }
    } else {
      tmp$1 = Js_exn.raiseError("Unexpected GraphQL query response");
    }
    tmp = tmp$1;
  }
  var value$3 = value["status"];
  return decode$1({
              id: value["id"],
              createdAt: !(value$1 == null) ? value$1 : undefined,
              source: tmp,
              status: !(value$3 == null) ? value$3 : undefined
            });
}

function ret_type$8(f) {
  return {};
}

var MT_Ret$8 = {};

var NotificationWidget__Notification__WithDrafts = {
  ppx_printed_query: ppx_printed_query$8,
  query: ppx_printed_query$8,
  parse: parse$8,
  name: "NotificationWidget__Notification__WithDrafts",
  ret_type: ret_type$8,
  MT_Ret: MT_Ret$8
};

var Fragments = {
  AML: undefined,
  $$Notification: undefined,
  MatchFragments: MatchFragments,
  decodeAmlListingData: decodeAmlListingData,
  PropertyAML: PropertyAML,
  RentalPropertyAML: RentalPropertyAML,
  decodeAMLSourceAgent: decodeAMLSourceAgent,
  RentalNeedAML: RentalNeedAML,
  BuyerNeedAML: BuyerNeedAML,
  sourceDecoder: sourceDecoder,
  decode: decode$1,
  NotificationWidget__Notification__WithDrafts: NotificationWidget__Notification__WithDrafts
};

function NotificationRow$ClientAvatar(props) {
  var agent = props.agent;
  var shouldShowPropertyDetails = CurrentUser.useShouldShowPropertyDetails(undefined, agent, undefined);
  var isMobile = MediaQuery.isMobile();
  if (typeof agent === "object" && shouldShowPropertyDetails) {
    var user = agent._0;
    var agentId = user.id;
    var match = user.membershipStatus;
    var src = match === "TERMINATED" ? AvatarSrcUrl.place_holder : Types__User.avatarURL(undefined, undefined, user);
    var url = "/agents/" + agentId;
    return JsxRuntime.jsx(Next__Atoms.UnstyledLink.make, {
                href: url,
                children: JsxRuntime.jsx(Avatar__Jsx3.make, {
                      src: src,
                      name: Types__User.label(user),
                      size: isMobile ? "tiny" : "small"
                    })
              });
  }
  return JsxRuntime.jsx(Avatar__Jsx3.make, {
              src: AvatarSrcUrl.place_holder,
              name: "A TAN user"
            });
}

var ClientAvatar = {
  make: NotificationRow$ClientAvatar
};

var make = NotificationTypeIcon.NotificationTypeIconContainer;

var IconContainer = {
  make: make
};

function NotificationRow$NotificationIcon(props) {
  var source = props.source;
  switch (source.TAG) {
    case "DraftSummary" :
        return JsxRuntime.jsx(make, {
                    children: JsxRuntime.jsx(Icon__Jsx3.make, {
                          icon: "Draft",
                          size: 30
                        })
                  });
    case "Showing" :
        return JsxRuntime.jsx(NotificationRow$ClientAvatar, {
                    agent: {
                      TAG: "User",
                      _0: source._0.property.agent
                    }
                  });
    case "AgentMatchLog" :
        var agentMatchLog = source._0;
        var agent;
        switch (agentMatchLog.TAG) {
          case "PropertyAgentMatchLog" :
          case "RentalPropertyAgentMatchLog" :
              agent = agentMatchLog._0.sourceOwner;
              break;
          case "BuyerNeedAgentMatchLog" :
          case "RentalNeedAgentMatchLog" :
              agent = {
                TAG: "User",
                _0: agentMatchLog._0.sourceOwner
              };
              break;
          
        }
        return JsxRuntime.jsx(NotificationRow$ClientAvatar, {
                    agent: agent
                  });
    
  }
}

var NotificationIcon = {
  make: NotificationRow$NotificationIcon
};

function NotificationRow(props) {
  var markAsRead = props.markAsRead;
  var notification = props.notification;
  var isMobile = MediaQuery.isMobile();
  var id = notification.id;
  var status = notification.status;
  var source = notification.source;
  var match;
  switch (source.TAG) {
    case "DraftSummary" :
        var match$1 = source._0;
        var match$2 = match$1.draftType;
        match = typeof match$2 === "object" ? [
            "Resume draft",
            "/properties/" + match$2.VAL + "/broadcast?resumingDraft=1"
          ] : [
            "Resume draft",
            "/properties/new?draftId=" + match$1.id
          ];
        break;
    case "Showing" :
        match = [
          "View details",
          "/properties/" + source._0.property.id
        ];
        break;
    case "AgentMatchLog" :
        match = [
          "View match",
          "/match_log/" + Notification__Types.AML.getId(source._0)
        ];
        break;
    
  }
  var link = match[1];
  var onClick = function (_e) {
    if (status !== "READ") {
      return Curry._1(markAsRead, id);
    }
    
  };
  var tmp;
  switch (source.TAG) {
    case "DraftSummary" :
        tmp = "DRAFT";
        break;
    case "Showing" :
        tmp = "SHOWING";
        break;
    case "AgentMatchLog" :
        tmp = "MATCH";
        break;
    
  }
  return JsxRuntime.jsxs(ViewStack.make, {
              px: Responsive.make({
                    NAME: "px",
                    VAL: 5
                  }, {
                    NAME: "px",
                    VAL: 15
                  }, undefined, undefined, undefined, undefined, undefined),
              py: Responsive.make({
                    NAME: "px",
                    VAL: 10
                  }, {
                    NAME: "px",
                    VAL: 15
                  }, undefined, undefined, undefined, undefined, undefined),
              alignItems: "center",
              flexDirection: "row",
              className: Curry._1(Css.style, {
                    hd: Css.borderBottom({
                          NAME: "px",
                          VAL: 1
                        }, "solid", Theme__Color.gallery),
                    tl: {
                      hd: Css.position("relative"),
                      tl: /* [] */0
                    }
                  }),
              background: status === "READ" ? undefined : Colors.alabasterAlt,
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(NotificationRow$NotificationIcon, {
                            source: source
                          }),
                      className: Curry._1(Css.style, {
                            hd: Css.zIndex(1),
                            tl: /* [] */0
                          })
                    }),
                JsxRuntime.jsx(Next__Atoms.UnstyledLink.make, {
                      href: link,
                      onClick: onClick,
                      className: Curry._1(Css.style, {
                            hd: Css.flexGrow(1.0),
                            tl: {
                              hd: Css.selector("&:after", {
                                    hd: Css.position("absolute"),
                                    tl: {
                                      hd: Css.top("zero"),
                                      tl: {
                                        hd: Css.right("zero"),
                                        tl: {
                                          hd: Css.bottom("zero"),
                                          tl: {
                                            hd: Css.left("zero"),
                                            tl: {
                                              hd: Css.contentRule({
                                                    NAME: "text",
                                                    VAL: "\" \""
                                                  }),
                                              tl: /* [] */0
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }),
                              tl: /* [] */0
                            }
                          }),
                      children: JsxRuntime.jsxs(View__Jsx3.make, {
                            children: [
                              JsxRuntime.jsx(NotificationText.make, {
                                    source: source,
                                    status: status
                                  }),
                              JsxRuntime.jsx(Text__Jsx3.make, {
                                    fontWeight: status === "READ" ? "regular" : "bold",
                                    className: Curry._1(Css.style, {
                                          hd: Css.textDecoration("underline"),
                                          tl: {
                                            hd: Css.textDecorationStyle("dashed"),
                                            tl: {
                                              hd: Css.textDecorationColor(Theme__Color.alto),
                                              tl: /* [] */0
                                            }
                                          }
                                        }),
                                    children: tmp
                                  })
                            ]
                          })
                    }),
                isMobile ? null : JsxRuntime.jsx(Next__Atoms.SecondaryButton.make, {
                        href: link,
                        onClick: onClick,
                        size: "small",
                        className: Curry._1(Css.style, {
                              hd: Css.zIndex(1),
                              tl: /* [] */0
                            }),
                        children: Caml_option.some(match[0])
                      })
              ]
            });
}

var make$1 = NotificationRow;

exports.Fragments = Fragments;
exports.ClientAvatar = ClientAvatar;
exports.IconContainer = IconContainer;
exports.NotificationIcon = NotificationIcon;
exports.make = make$1;
/* make Not a pure module */
