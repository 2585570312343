// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var CssJs = require("bs-css-emotion/src/CssJs.bs.js");
var Client = require("../../../reason/clients/types/Client.bs.js");
var Property = require("../../../reason/clients/types/Property.bs.js");
var DateFns = require("date-fns");
var Icon__Jsx3 = require("../../../uikit/reason/atoms/Icon/Icon__Jsx3.bs.js");
var Text__Jsx3 = require("../../../uikit/reason/helpers/Text__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var CurrentUser = require("../../../reason/common/CurrentUser/CurrentUser.bs.js");
var Types__User = require("../../../reason/user/Types__User.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var DraftSummary = require("../../../components/MyDraftsWidget/DraftSummary.bs.js");
var JsxRuntime = require("react/jsx-runtime");

function notificationMatchingAgentName(agent) {
  if (typeof agent !== "object") {
    return "A TAN agent";
  }
  var agent$1 = agent._0;
  if (CurrentUser.agentIsCurrentUser(agent$1.id)) {
    return "You";
  } else {
    return Types__User.shortLabel(agent$1);
  }
}

function notificationMatchingAgentId(agent) {
  if (typeof agent !== "object") {
    return ;
  } else {
    return agent._0.id;
  }
}

function NotificationText$ShowingNotification(props) {
  var showing = props.showing;
  var agent = showing.property.agent;
  var agentText = notificationMatchingAgentName({
        TAG: "User",
        _0: agent
      });
  var shouldShowPropertyDetails = CurrentUser.useShouldShowPropertyDetails(agent.id, undefined, undefined);
  var property = showing.property;
  var address = property.address;
  var $$location = Property.DisplayLocation.toString(property.location);
  var date = DateFns.format(showing.startDate, "EEE, MMM d");
  var time = DateFns.format(showing.startDate, "p - ") + DateFns.format(showing.endDate, "p");
  var duration = date + (", " + time);
  var verb = agentText === "You" ? "are" : "is";
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                shouldShowPropertyDetails ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                        children: [
                          agentText + " " + verb + " showing ",
                          address.NAME === "Public" ? address.VAL + " in " + $$location : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                  children: [
                                    $$location,
                                    " (",
                                    JsxRuntime.jsx(Icon__Jsx3.make, {
                                          icon: "Lock",
                                          className: CssJs.style([
                                                CssJs.verticalAlign("baseline"),
                                                CssJs.display("inline")
                                              ])
                                        }),
                                    address.VAL !== undefined ? " Hidden Address" : " Contact agent for address",
                                    ")"
                                  ]
                                })
                        ]
                      }) : JsxRuntime.jsx(JsxRuntime.Fragment, {
                        children: Caml_option.some("A TAN agent is showing " + $$location)
                      }),
                JsxRuntime.jsx("br", {}),
                duration
              ]
            });
}

var ShowingNotification = {
  make: NotificationText$ShowingNotification
};

function NotificationText$PropertyMatchText(props) {
  var amlInfo = props.amlInfo;
  var agentText = notificationMatchingAgentName(amlInfo.sourceOwner);
  var agentId = notificationMatchingAgentId(amlInfo.sourceOwner);
  var shouldShowPropertyDetails = CurrentUser.useShouldShowPropertyDetails(agentId, undefined, undefined);
  var match = amlInfo.eventType;
  var verb = match !== undefined && (match === "LISTING_REBROADCASTED" || match === "QUIET_SELLER_BROADCASTED" || match === "LISTING_EDITED") ? "updated" : "added";
  var matchesText;
  var exit = 0;
  var client;
  var matches;
  var matches$1 = amlInfo.newMatches;
  var exit$1 = 0;
  var len = matches$1.length;
  if (len !== 1) {
    if (len !== 0) {
      exit$1 = 3;
    } else {
      var matches$2 = amlInfo.existingMatches;
      if (matches$2.length !== 1) {
        matches = matches$2;
        exit = 2;
      } else {
        var match$1 = matches$2[0];
        client = match$1.client;
        exit = 1;
      }
    }
  } else {
    var match$2 = matches$1[0];
    if (amlInfo.existingMatches.length !== 0) {
      exit$1 = 3;
    } else {
      client = match$2.client;
      exit = 1;
    }
  }
  if (exit$1 === 3) {
    if (amlInfo.existingMatches.length !== 0) {
      var length = matches$1.length;
      matchesText = length.toString() + " more of your buyers";
    } else {
      matches = matches$1;
      exit = 2;
    }
  }
  switch (exit) {
    case 1 :
        var clientName = Core__Option.getOr(Client.label(client), "client name");
        matchesText = "your buyer " + clientName;
        break;
    case 2 :
        var length$1 = matches.length;
        matchesText = length$1.toString() + " of your buyers";
        break;
    
  }
  if (shouldShowPropertyDetails) {
    return agentText + " " + verb + " a property that matches " + matchesText;
  } else {
    return "A TAN agent " + verb + " a property that matches " + matchesText;
  }
}

var PropertyMatchText = {
  make: NotificationText$PropertyMatchText
};

function NotificationText$RentalPropertyMatchText(props) {
  var amlInfo = props.amlInfo;
  var agentText = notificationMatchingAgentName(amlInfo.sourceOwner);
  var agentId = notificationMatchingAgentId(amlInfo.sourceOwner);
  var shouldShowPropertyDetails = CurrentUser.useShouldShowPropertyDetails(agentId, undefined, undefined);
  var match = amlInfo.eventType;
  var verb = match !== undefined && (match === "LISTING_REBROADCASTED" || match === "QUIET_SELLER_BROADCASTED" || match === "LISTING_EDITED") ? "updated" : "added";
  var matchesText;
  var exit = 0;
  var client;
  var matches;
  var matches$1 = amlInfo.newMatches;
  var exit$1 = 0;
  var len = matches$1.length;
  if (len !== 1) {
    if (len !== 0) {
      exit$1 = 3;
    } else {
      var matches$2 = amlInfo.existingMatches;
      if (matches$2.length !== 1) {
        matches = matches$2;
        exit = 2;
      } else {
        var match$1 = matches$2[0];
        client = match$1.client;
        exit = 1;
      }
    }
  } else {
    var match$2 = matches$1[0];
    if (amlInfo.existingMatches.length !== 0) {
      exit$1 = 3;
    } else {
      client = match$2.client;
      exit = 1;
    }
  }
  if (exit$1 === 3) {
    if (amlInfo.existingMatches.length !== 0) {
      var length = matches$1.length;
      matchesText = length.toString() + " more of your clients";
    } else {
      matches = matches$1;
      exit = 2;
    }
  }
  switch (exit) {
    case 1 :
        var clientName = Core__Option.getOr(Client.label(client), "client name");
        matchesText = "your buyer " + clientName;
        break;
    case 2 :
        var length$1 = matches.length;
        matchesText = length$1.toString() + " of your clients";
        break;
    
  }
  if (shouldShowPropertyDetails) {
    return agentText + " " + verb + " a rental property that matches " + matchesText;
  } else {
    return "A TAN agent " + verb + " a rental property that matches " + matchesText;
  }
}

var RentalPropertyMatchText = {
  make: NotificationText$RentalPropertyMatchText
};

function buyerNeedText(amlInfo) {
  var agentText = notificationMatchingAgentName({
        TAG: "User",
        _0: amlInfo.sourceOwner
      });
  var match = amlInfo.eventType;
  var verb = match !== undefined ? (
      match === "BROADCAST_CLIENT_NEED_CREATED" || match === "PRIVATE_CLIENT_NEED_CREATED" ? (
          agentText === "You" ? "have" : "has"
        ) : "updated"
    ) : (
      agentText === "You" ? "have" : "has"
    );
  var matchesText;
  var exit = 0;
  var address;
  var client;
  var matches;
  var matches$1 = amlInfo.newMatches;
  var exit$1 = 0;
  var len = matches$1.length;
  if (len !== 1) {
    if (len !== 0) {
      exit$1 = 3;
    } else {
      var matches$2 = amlInfo.existingMatches;
      if (matches$2.length !== 1) {
        matches = matches$2;
        exit = 2;
      } else {
        var match$1 = matches$2[0];
        address = match$1.address;
        client = match$1.client;
        exit = 1;
      }
    }
  } else {
    var match$2 = matches$1[0];
    if (amlInfo.existingMatches.length !== 0) {
      exit$1 = 3;
    } else {
      address = match$2.address;
      client = match$2.client;
      exit = 1;
    }
  }
  if (exit$1 === 3) {
    if (amlInfo.existingMatches.length !== 0) {
      var length = matches$1.length;
      matchesText = length.toString() + " more of your sellers";
    } else {
      matches = matches$1;
      exit = 2;
    }
  }
  switch (exit) {
    case 1 :
        matchesText = Core__Option.getOr(Core__Option.map(Client.label(client), (function (name) {
                    return name + "'s property";
                  })), Property.Address.toString(address));
        break;
    case 2 :
        var length$1 = matches.length;
        matchesText = length$1.toString() + " of your sellers";
        break;
    
  }
  return agentText + " " + verb + " a buyer that matches " + matchesText;
}

function rentalNeedText(amlInfo) {
  var agentText = notificationMatchingAgentName({
        TAG: "User",
        _0: amlInfo.sourceOwner
      });
  var match = amlInfo.eventType;
  var verb = match !== undefined ? (
      match === "BROADCAST_CLIENT_NEED_CREATED" || match === "PRIVATE_CLIENT_NEED_CREATED" ? (
          agentText === "You" ? "have" : "has"
        ) : "updated"
    ) : (
      agentText === "You" ? "have" : "has"
    );
  var matchesText;
  var exit = 0;
  var address;
  var client;
  var matches;
  var matches$1 = amlInfo.newMatches;
  var exit$1 = 0;
  var len = matches$1.length;
  if (len !== 1) {
    if (len !== 0) {
      exit$1 = 3;
    } else {
      var matches$2 = amlInfo.existingMatches;
      if (matches$2.length !== 1) {
        matches = matches$2;
        exit = 2;
      } else {
        var match$1 = matches$2[0];
        address = match$1.address;
        client = match$1.client;
        exit = 1;
      }
    }
  } else {
    var match$2 = matches$1[0];
    if (amlInfo.existingMatches.length !== 0) {
      exit$1 = 3;
    } else {
      address = match$2.address;
      client = match$2.client;
      exit = 1;
    }
  }
  if (exit$1 === 3) {
    if (amlInfo.existingMatches.length !== 0) {
      var length = matches$1.length;
      matchesText = length.toString() + " more of your clients";
    } else {
      matches = matches$1;
      exit = 2;
    }
  }
  switch (exit) {
    case 1 :
        matchesText = Core__Option.getOr(Core__Option.map(Client.label(client), (function (name) {
                    return name + "'s property";
                  })), Property.Address.toString(address));
        break;
    case 2 :
        var length$1 = matches.length;
        matchesText = length$1.toString() + " of your clients";
        break;
    
  }
  return agentText + " " + verb + " a client that matches " + matchesText;
}

function NotificationText(props) {
  var source = props.source;
  var inner;
  switch (source.TAG) {
    case "DraftSummary" :
        var draft = source._0;
        var match = draft.draftType;
        inner = typeof match === "object" ? (
            match.NAME === "REBROADCAST" ? "Your draft for rebroadcasting " + (DraftSummary.contentToString(draft) + " is unfinished") : "Your draft for broadcasting your quiet seller at " + (DraftSummary.contentToString(draft) + " is unfinished")
          ) : "Your draft " + (DraftSummary.contentToString(draft) + " is unfinished");
        break;
    case "Showing" :
        inner = JsxRuntime.jsx(NotificationText$ShowingNotification, {
              showing: source._0
            });
        break;
    case "AgentMatchLog" :
        var agentMatchLog = source._0;
        switch (agentMatchLog.TAG) {
          case "PropertyAgentMatchLog" :
              inner = JsxRuntime.jsx(NotificationText$PropertyMatchText, {
                    amlInfo: agentMatchLog._0
                  });
              break;
          case "RentalPropertyAgentMatchLog" :
              inner = JsxRuntime.jsx(NotificationText$RentalPropertyMatchText, {
                    amlInfo: agentMatchLog._0
                  });
              break;
          case "BuyerNeedAgentMatchLog" :
              inner = buyerNeedText(agentMatchLog._0);
              break;
          case "RentalNeedAgentMatchLog" :
              inner = rentalNeedText(agentMatchLog._0);
              break;
          
        }
        break;
    
  }
  return JsxRuntime.jsx(Text__Jsx3.make, {
              as_: "div",
              fontWeight: props.status === "READ" ? "regular" : "bold",
              children: inner
            });
}

var make = NotificationText;

exports.notificationMatchingAgentName = notificationMatchingAgentName;
exports.notificationMatchingAgentId = notificationMatchingAgentId;
exports.ShowingNotification = ShowingNotification;
exports.PropertyMatchText = PropertyMatchText;
exports.RentalPropertyMatchText = RentalPropertyMatchText;
exports.buyerNeedText = buyerNeedText;
exports.rentalNeedText = rentalNeedText;
exports.make = make;
/* CssJs Not a pure module */
