// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Link = require("next/link").default;
var Icon__Jsx3 = require("../../../uikit/reason/atoms/Icon/Icon__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Next__Atoms = require("../../../reason/next/Next__Atoms.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var RescriptCore = require("@rescript/core/src/RescriptCore.bs.js");
var TailwindMerge = require("tailwind-merge");
var NotificationRow = require("./NotificationRow.bs.js");
var AgentProfilePhoto = require("../../../v5/common/Agent/AgentProfilePhoto.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var NotificationWidgetTexts = require("./NotificationWidgetTexts.bs.js");
var NotificationWidgetItem_notification_graphql = require("../../../__generated__/NotificationWidgetItem_notification_graphql.bs.js");
var NotificationWidgetItemSource_notification_graphql = require("../../../__generated__/NotificationWidgetItemSource_notification_graphql.bs.js");

var convertFragment = NotificationWidgetItem_notification_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetItem_notification_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NotificationWidgetItem_notification_graphql.node, convertFragment);
}

var NotificationFragment_notificationStatus_decode = NotificationWidgetItem_notification_graphql.Utils.notificationStatus_decode;

var NotificationFragment_notificationStatus_fromString = NotificationWidgetItem_notification_graphql.Utils.notificationStatus_fromString;

var NotificationFragment = {
  notificationStatus_decode: NotificationFragment_notificationStatus_decode,
  notificationStatus_fromString: NotificationFragment_notificationStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

var convertFragment$1 = NotificationWidgetItemSource_notification_graphql.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(NotificationWidgetItemSource_notification_graphql.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, NotificationWidgetItemSource_notification_graphql.node, convertFragment$1);
}

var NotificationSourceFragment_draftOrigin_decode = NotificationWidgetItemSource_notification_graphql.Utils.draftOrigin_decode;

var NotificationSourceFragment_draftOrigin_fromString = NotificationWidgetItemSource_notification_graphql.Utils.draftOrigin_fromString;

var NotificationSourceFragment = {
  draftOrigin_decode: NotificationSourceFragment_draftOrigin_decode,
  draftOrigin_fromString: NotificationSourceFragment_draftOrigin_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1
};

var avatarStyle = "w-[50px] h-[50px]";

function NotificationWidgetItem(props) {
  var markAsRead = props.markAsRead;
  var notification = RescriptRelay_Fragment.useFragment(NotificationWidgetItem_notification_graphql.node, convertFragment, props.notification);
  var source = RescriptRelay_Fragment.useFragment(NotificationWidgetItemSource_notification_graphql.node, convertFragment$1, notification.fragmentRefs);
  var onClick = function (_e) {
    var match = notification.status;
    if (match === "UNREAD") {
      return Curry._1(markAsRead, notification.id);
    }
    
  };
  var match;
  if (typeof source === "object") {
    var variant = source.NAME;
    if (variant === "UnfinishedDraftNotification") {
      var match$1 = source.VAL.draft;
      var match$2 = match$1.origin;
      if (match$2 !== undefined) {
        if (match$2 === "REBROADCAST" || match$2 === "BROADCAST_QUIET_SELLER") {
          var match$3 = match$1.publishedProperty;
          match = match$3 !== undefined ? [
              "Resume draft",
              "/properties/" + match$3.id + "/broadcast?resumingDraft=1"
            ] : RescriptCore.panic("Unexpected source.");
        } else {
          match = match$2 === "NEW" ? [
              "Resume draft",
              "/properties/new?draftId=" + match$1.databaseId
            ] : RescriptCore.panic("Unexpected source.");
        }
      } else {
        match = RescriptCore.panic("Unexpected source.");
      }
    } else {
      match = variant === "AgentMatchLogNotification" ? [
          "View match",
          "/match_log/" + source.VAL.agentMatchLog.databaseId
        ] : (
          variant === "UpcomingShowingNotification" ? [
              "View details",
              "/properties/" + source.VAL.showing.property.id
            ] : RescriptCore.panic("Unexpected source.")
        );
    }
  } else {
    match = RescriptCore.panic("Unexpected source.");
  }
  var link = match[1];
  var tmp;
  var exit = 0;
  if (typeof source === "object") {
    var variant$1 = source.NAME;
    if (variant$1 === "UnfinishedDraftNotification") {
      tmp = JsxRuntime.jsx(NotificationRow.IconContainer.make, {
            children: JsxRuntime.jsx(Icon__Jsx3.make, {
                  icon: "Draft",
                  size: 30
                })
          });
    } else if (variant$1 === "AgentMatchLogNotification") {
      var match$4 = source.VAL.agentMatchLogByType;
      if (match$4 !== undefined && typeof match$4 === "object") {
        var variant$2 = match$4.NAME;
        if (variant$2 === "RentalPropertyAgentMatchLogV5" || variant$2 === "BuyerNeedAgentMatchLogV5" || variant$2 === "RentalNeedAgentMatchLogV5") {
          var match$5 = match$4.VAL.source;
          if (match$5 !== undefined) {
            var match$6 = match$5.agent;
            if (match$6 !== undefined) {
              tmp = JsxRuntime.jsx(AgentProfilePhoto.make, {
                    profilePhoto: match$6.fragmentRefs,
                    className: avatarStyle
                  });
            } else {
              exit = 1;
            }
          } else {
            exit = 1;
          }
        } else if (variant$2 === "ForSalePropertyAgentMatchLogV5") {
          var match$7 = match$4.VAL.source;
          if (match$7 !== undefined) {
            var match$8 = match$7.agentV2;
            if (match$8 !== undefined && typeof match$8 === "object" && match$8.NAME === "VisibleForSalePropertyAgent") {
              var match$9 = match$8.VAL.agent;
              if (match$9 !== undefined) {
                tmp = JsxRuntime.jsx(AgentProfilePhoto.make, {
                      profilePhoto: match$9.fragmentRefs,
                      className: avatarStyle
                    });
              } else {
                exit = 1;
              }
            } else {
              exit = 1;
            }
          } else {
            exit = 1;
          }
        } else {
          exit = 1;
        }
      } else {
        exit = 1;
      }
    } else if (variant$1 === "UpcomingShowingNotification") {
      var match$10 = source.VAL.showing.property.agentV2;
      if (match$10 !== undefined && typeof match$10 === "object" && match$10.NAME === "VisibleForSalePropertyAgent") {
        var match$11 = match$10.VAL.agent;
        if (match$11 !== undefined) {
          tmp = JsxRuntime.jsx(AgentProfilePhoto.make, {
                profilePhoto: match$11.fragmentRefs,
                className: avatarStyle
              });
        } else {
          exit = 1;
        }
      } else {
        exit = 1;
      }
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx(AgentProfilePhoto.Avatar.make, {
          alt: "A TAN user",
          className: avatarStyle
        });
  }
  var tmp$1;
  if (typeof source === "object") {
    var variant$3 = source.NAME;
    if (variant$3 === "UnfinishedDraftNotification") {
      var match$12 = source.VAL.draft;
      var match$13 = match$12.draftProperty;
      if (match$13 !== undefined) {
        var origin = match$12.origin;
        var address = match$13.address;
        var match$14 = Core__Option.map(match$13.userSelectedArea, (function (v) {
                return v.name;
              }));
        var text = address !== undefined ? (
            match$14 !== undefined ? address + (", " + match$14) : address
          ) : (
            match$14 !== undefined ? match$14 : "(no address entered)"
          );
        tmp$1 = origin !== undefined ? (
            origin === "BROADCAST_QUIET_SELLER" ? "Your draft for broadcasting your quiet seller at " + text + " is unfinished" : (
                origin === "REBROADCAST" ? "Your draft for rebroadcasting " + text + " is unfinished" : (
                    origin === "NEW" ? "Your draft " + text + " is unfinished" : ""
                  )
              )
          ) : "";
      } else {
        tmp$1 = null;
      }
    } else if (variant$3 === "AgentMatchLogNotification") {
      var agentMatchLogByType = source.VAL.agentMatchLogByType;
      if (agentMatchLogByType !== undefined) {
        var variant$4 = agentMatchLogByType.NAME;
        tmp$1 = variant$4 === "BuyerNeedAgentMatchLogV5" ? JsxRuntime.jsx(NotificationWidgetTexts.BuyerNeedMatchText.make, {
                aml: agentMatchLogByType.VAL.fragmentRefs
              }) : (
            variant$4 === "RentalPropertyAgentMatchLogV5" ? JsxRuntime.jsx(NotificationWidgetTexts.RentalPropertyMatchText.make, {
                    aml: agentMatchLogByType.VAL.fragmentRefs
                  }) : (
                variant$4 === "UnselectedUnionMember" ? null : (
                    variant$4 === "ForSalePropertyAgentMatchLogV5" ? JsxRuntime.jsx(NotificationWidgetTexts.PropertyMatchText.make, {
                            aml: agentMatchLogByType.VAL.fragmentRefs
                          }) : JsxRuntime.jsx(NotificationWidgetTexts.RentalNeedMatchText.make, {
                            aml: agentMatchLogByType.VAL.fragmentRefs
                          })
                  )
              )
          );
      } else {
        tmp$1 = null;
      }
    } else {
      tmp$1 = variant$3 === "UpcomingShowingNotification" ? JsxRuntime.jsx(NotificationWidgetTexts.ShowingNotification.make, {
              showing: source.VAL.showing.fragmentRefs
            }) : null;
    }
  } else {
    tmp$1 = null;
  }
  var variant$5 = source.NAME;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: tmp,
                      className: ""
                    }),
                JsxRuntime.jsxs(Link, {
                      href: link,
                      className: "grow text-zinc-600 hover:text-zinc-600",
                      children: [
                        JsxRuntime.jsx("div", {
                              children: tmp$1,
                              className: notification.status === "READ" ? "font-normal" : "font-semibold"
                            }),
                        JsxRuntime.jsx("div", {
                              children: variant$5 === "AgentMatchLogNotification" ? "MATCH" : (
                                  variant$5 === "UpcomingShowingNotification" ? "SHOWING" : (
                                      variant$5 === "UnselectedUnionMember" ? "" : "DRAFT"
                                    )
                                ),
                              className: TailwindMerge.twMerge([
                                    "underline decoration-dashed decoration-zinc-300",
                                    notification.status === "READ" ? "font-normal" : "font-bold"
                                  ])
                            })
                      ]
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Next__Atoms.SecondaryButton.make, {
                            href: link,
                            onClick: onClick,
                            size: "small",
                            className: "z-10",
                            children: Caml_option.some(match[0])
                          }),
                      className: "hidden md:block"
                    })
              ],
              className: TailwindMerge.twMerge([
                    "flex flex-row items-center space-x-4 py-4 px-2 border-b border-gray-200 relative",
                    notification.status === "READ" ? "" : "bg-gray-50"
                  ])
            });
}

var make = NotificationWidgetItem;

exports.NotificationFragment = NotificationFragment;
exports.NotificationSourceFragment = NotificationSourceFragment;
exports.avatarStyle = avatarStyle;
exports.make = make;
/* next/link Not a pure module */
